import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { Store } from "redux";
import { useNavigate } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { connect, useDispatch } from "react-redux";
import { get, throttle } from "lodash";
import mediator from "@tvg/mediator";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { getAccountNumber, getResidenceState } from "@urp/store-selectors";
import {
  getTrackAbbr,
  getTrackLocation,
  getTrackSubtitle
} from "../../utils/trackInfo";
import useFavorite from "../../hooks/useFavorite";
import {
  onReturnArrowClickGtm,
  onSeeTodaysRacingClickGtm,
  onTrackListPageViewGtm
} from "../../utils/gtm";
import { setFetchedWagerProfile } from "../../redux/actions";
import { TrackInfoProps as Props } from "../../types";
import TrackInfoChild from "./components/TrackInfoChild";

const defaultTrackInfo = {
  code: "",
  name: "",
  location: {
    city: "",
    state: "",
    country: ""
  },
  imageName: "",
  imageLink: ""
};

export const TrackInfo = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shouldUpdate, setShouldUpdate] = useState(
    props.tracksData.length === 0
  );

  /**
   * Manages eventual Wager Profile changes on Login, set state for Apollo to
   * re-fetch data according to the new WagerProfile and updates the previously fetched Profile in Redux
   */
  useEffect(() => {
    if (props.wagerProfile !== props.fetchedWagerProfile) {
      setShouldUpdate(true);
      dispatch(setFetchedWagerProfile(props.wagerProfile));
    }
  }, [props.wagerProfile]);

  useEffect(() => {
    if (props.device === "desktop") {
      onTrackListPageViewGtm(
        props.location,
        props.residenceState,
        props.accountNumber
      );
    }
  }, [props.accountNumber]);

  const getCorrectTrackInfo = () => {
    const trackAbbr = getTrackAbbr(props.history);
    const trackInfo = props.tracksData.find(
      (track) => track.code === trackAbbr
    );
    return props.isLoading ? undefined : trackInfo;
  };

  const trackInfo = getCorrectTrackInfo();

  const fromQuickLink =
    props.device === "desktop"
      ? props.fromQuickLinks
      : get(props.history, "location.state.fromQuickLink", false);

  useEffect(() => {
    if (props.tracksData.length > 0 && !trackInfo) {
      navigate("/", { replace: true });
    }
  }, [trackInfo, JSON.stringify(props.tracksData)]);

  const { isFavorite, toggleFavoriteAction } = useFavorite(
    trackInfo && props.tracksWithId && props.tracksWithId[trackInfo.code]
      ? props.tracksWithId[trackInfo.code]
      : null,
    trackInfo || defaultTrackInfo,
    props.isLogged,
    props.accountNumber,
    props.device,
    props.isAccountCompliant
  );

  const fromRacetracks = get(
    props.history,
    "location.state.fromRacetracks",
    false
  );

  const onBackClick = () => {
    onReturnArrowClickGtm();
    if ((fromRacetracks || fromQuickLink) && props.device !== "desktop") {
      navigate(-1);
    } else {
      if (props.device === "desktop") {
        mediator.base.dispatch({
          type: "TVG4_NAVIGATION",
          payload: { route: "/racetracks" }
        });
      }
      navigate("/racetracks");
    }
  };

  const onEmptyActionClick = () => {
    onSeeTodaysRacingClickGtm(window.location.origin + "/races");
    navigate("/races");

    if (props.device === "desktop") {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: "/races" }
      });
    }
  };

  const handleToggleFavorite = useCallback(
    throttle(toggleFavoriteAction, 1500, {
      trailing: false
    }),
    [toggleFavoriteAction]
  );

  const onRaceClickSEORaceTracks = (url: string) => {
    if (props.device === "desktop") {
      navigate(url);
    }
  };

  // Fix for weird scroll behaviour for Safari on iPad iOS 15
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [shouldUpdate]);

  return (
    <TrackInfoChild
      dispatch={dispatch}
      device={props.device}
      shouldUpdate={shouldUpdate}
      content={props.content}
      trackInfo={trackInfo}
      isFavorite={isFavorite}
      isLoadingContent={props.isLoadingContent}
      trackSubtitle={getTrackSubtitle(
        get(trackInfo, "location.country", ""),
        get(trackInfo, "location.state", "")
      )}
      trackLocation={getTrackLocation(props.content)}
      accountNumber={props.accountNumber}
      isLogged={props.isLogged}
      rdaClient={props.rdaClient}
      // @ts-ignore
      fcpClient={props.fcpClient}
      onEmptyActionClick={onEmptyActionClick}
      onBackClick={onBackClick}
      handleToggleFavorite={handleToggleFavorite}
      wagerProfile={props.wagerProfile}
      setTracksData={props.setTracksData}
      setShouldUpdate={setShouldUpdate}
      onRaceClickSEORaceTracks={onRaceClickSEORaceTracks}
    />
  );
};

const mapStateToProps = (store: Store) => ({
  tracksData: get(store, "app.seoTrackList", []),
  tracksWithId: get(store, "userFavorites.tracksWithId", {}),
  isLogged: get(store, "userData.logged", false),
  accountNumber: getAccountNumber(store),
  residenceState: getResidenceState(store),
  wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
  isAccountCompliant: isAccountCompliantSelector(store),
  fetchedWagerProfile: get(
    store,
    "seoRaceTracks.fetchedWagerProfile",
    "PORT-Generic"
  )
});

export default connect(mapStateToProps)(withRouter(TrackInfo));
