import { buildColor } from "@tvg/design-system";
import styled, { css } from "styled-components";

export const EmptyContainer = styled.div<{
  hasCenterAlignment: boolean;
}>`
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ hasCenterAlignment }) =>
    hasCenterAlignment
      ? css`
          justify-content: center;
        `
      : css`
          justify-content: flex-start;
          padding-top: 60px;
        `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  background-color: ${buildColor("white", "900")};
  border-radius: 31px;
  margin-bottom: 20px;
`;
