import React, {
  useMemo,
  useEffect,
  useLayoutEffect,
  useState,
  useRef
} from "react";
import {
  DefaultRootState,
  connect,
  useDispatch,
  useSelector
} from "react-redux";
import withRouter from "@tvg/utils/withCustomRouter";
import { get, sortBy, groupBy, throttle } from "lodash";
import mediator from "@tvg/mediator";
import { Device } from "@tvg/ts-types/Device";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { getIsLogged, getAccountNumber } from "@urp/store-selectors";
import TracksListChild from "./components/TracksListChild";
import { setFetchedWagerProfile } from "../../redux/actions";
import {
  getCountryFullName,
  getUSStateFullName,
  getCanStateFullName
} from "../../utils/trackInfo";
import {
  TrackData,
  TrackListSections,
  TracksListProps as Props
} from "../../types";

export const TrackList = (props: Props) => {
  const dispatch = useDispatch();
  const [shouldUpdate, setShouldUpdate] = useState(
    props.tracksData.length === 0
  );
  const [filteredTracks, setFilteredTracks] = useState(props.tracksData);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPageHeader, setShowPageHeader] = useState(true);
  const [showCancelSearch, setShowCancelSearch] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const isLogged = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const isAccountCompliant = useSelector(isAccountCompliantSelector);

  /**
   * Manages eventual Wager Profile changes on Login, set state for Apollo to
   * re-fetch data according to the new WagerProfile and updates the previously fetched Profile in Redux
   */
  useEffect(() => {
    if (props.wagerProfile !== props.fetchedWagerProfile) {
      setShouldUpdate(true);
    }
  }, [props.wagerProfile]);

  useEffect(() => {
    if (
      (filteredTracks.length === 0 && props.tracksData.length > 0) ||
      props.tracksData.length !== filteredTracks.length
    ) {
      setFilteredTracks(props.tracksData);
      // We just update the fetched wager profile after the tracks are setted, this prevents that a logout in TVG4 keeps tracks from previous logged wager profile
      dispatch(setFetchedWagerProfile(props.wagerProfile));
      setSearchQuery("");
    }
  }, [props.tracksData]);

  const sections: TrackListSections[] = useMemo(() => {
    if (filteredTracks.length === 0) {
      return [];
    }
    const { usaTracks, intlTracks } = filteredTracks.reduce<{
      usaTracks: TrackData[];
      intlTracks: TrackData[];
    }>(
      (prev, track) => {
        if (track.location.country === "USA") {
          prev.usaTracks.push(track);
        } else if (track.location.country !== "USA") {
          prev.intlTracks.push(track);
        }
        return prev;
      },
      { usaTracks: [], intlTracks: [] }
    );
    return [
      {
        type: "usa",
        title: "USA Tracks",
        data: groupBy(sortBy(usaTracks, "location.state"), "location.state")
      },
      {
        type: "intl",
        title: "International Tracks",
        data: groupBy(
          sortBy(intlTracks, "location.country"),
          "location.country"
        )
      }
    ];
  }, [filteredTracks]);

  useEffect(
    throttle(
      () => {
        const searchTerm = searchQuery.toLowerCase();
        const newTracks = props.tracksData.filter(
          (track) =>
            track.name.toLowerCase().includes(searchTerm) ||
            track.location.city?.toLowerCase().includes(searchTerm) ||
            track.location.state?.toLowerCase().includes(searchTerm) ||
            track.location.country?.toLowerCase().includes(searchTerm) ||
            getCountryFullName(track.location.country)
              .toLowerCase()
              .includes(searchTerm) ||
            (!!track.location.state &&
              track.location.country === "USA" &&
              getUSStateFullName(track.location.state)
                .toLowerCase()
                .includes(searchTerm)) ||
            (!!track.location.state &&
              track.location.country === "CAN" &&
              getCanStateFullName(track.location.state)
                .toLowerCase()
                .includes(searchTerm))
        );

        setFilteredTracks(newTracks);
      },
      500,
      {
        trailing: true
      }
    ),
    [searchQuery]
  );

  const onBackClick = () => {
    props.history.push("/account");
  };

  const onTrackClick = (destinationUrl: string) => {
    if (props.device === Device.DESKTOP) {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: destinationUrl }
      });
    }
    props.history.push(destinationUrl, {
      fromRacetracks: true
    });
  };

  const onCancelSearch = () => {
    setSearchQuery("");
    setShowPageHeader(true);
    setShowCancelSearch(false);
  };

  const onBlurSearch = () => {
    if (!searchQuery) {
      if (props.device !== Device.DESKTOP) {
        setShowPageHeader(true);
      }
      setShowCancelSearch(false);
    }
  };

  const onFocusSearch = () => {
    if (props.device !== Device.DESKTOP) {
      setShowPageHeader(false);
    }
    setShowCancelSearch(true);
  };

  // Fix for weird scroll behaviour for Safari on iPad iOS 15
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [shouldUpdate]);

  return (
    <TracksListChild
      // @ts-ignore
      fcpClient={props.fcpClient}
      dispatch={dispatch}
      device={props.device}
      tracksData={props.tracksData}
      wagerProfile={props.wagerProfile}
      shouldUpdate={shouldUpdate}
      isLoading={shouldUpdate}
      sections={sections}
      tracksWithId={props.tracksWithId}
      onBackClick={props.device === Device.DESKTOP ? undefined : onBackClick}
      onTrackClick={onTrackClick}
      setTracksData={props.setTracksData}
      setShouldUpdate={setShouldUpdate}
      onCancelSearch={onCancelSearch}
      onBlurSearch={onBlurSearch}
      onFocusSearch={onFocusSearch}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      showPageHeader={props.device === Device.DESKTOP ? true : showPageHeader}
      showCancelSearch={showCancelSearch}
      searchInputRef={searchInputRef}
      enableSeoRaceTracksSearch={props.enableSeoRaceTracksSearch}
      isLogged={isLogged}
      accountNumber={accountNumber}
      isAccountCompliant={isAccountCompliant}
    />
  );
};

const mapStateToProps = (store: DefaultRootState) => ({
  tracksData: get(store, "app.seoTrackList", []),
  tracksWithId: get(store, "userFavorites.tracksWithId", {}),
  wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
  fetchedWagerProfile: get(
    store,
    "seoRaceTracks.fetchedWagerProfile",
    "PORT-Generic"
  ),
  enableSeoRaceTracksSearch: get(
    store,
    "capi.featureToggles.enableSeoRaceTracksSearch",
    false
  )
});

export default connect(mapStateToProps)(withRouter(TrackList));
