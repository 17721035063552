import { get } from "lodash";
import { State } from "./reducers/index";

export const getStoreUserData = (store: State) => get(store, "userData");

export const getFeatureToggles = (store: State) =>
  get(store, "capi.featureToggles");

export const getStoreFeatures = (store: State) => get(store, "capi.features");

export const getMessages = (store: State) => get(store, "capi.messages");

export const getTracksData = (store: State) =>
  get(store, "app.seoTrackList", []);

export const getTodaysTracks = (store: State) =>
  get(store, "app.todaysTracks", []);
