// @flow
let xDown = null;
let timeStamp = null;

export const handleTouchStart = (evt: SyntheticTouchEvent<*>) => {
  xDown = evt.touches[0].clientX;
};

export const handleTouchMove = (evt: SyntheticTouchEvent<*>): number => {
  if (timeStamp && Date.now() - timeStamp < 1000) {
    return 0;
  }

  timeStamp = Date.now();
  // $FlowFixMe
  return xDown - evt.touches[0].clientX;
};

export const evaluateReachingEdge = (e: SyntheticTouchEvent<*>) => {
  const scrollableElement = e.currentTarget;
  let edgeHasBeenReached = false;
  if (scrollableElement) {
    const { scrollLeft, scrollWidth, clientWidth } = scrollableElement;
    edgeHasBeenReached =
      // $FlowFixMe
      (scrollLeft === 0 && xDown - e.touches[0].clientX < 0) ||
      (scrollLeft + clientWidth === scrollWidth &&
        // $FlowFixMe
        xDown - e.touches[0].clientX > 0);
  }

  return edgeHasBeenReached;
};
