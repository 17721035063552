import { gql } from "@apollo/client";

export const GET_RACES_MTP_STATUS = gql`
  query getRacesMtpStatus(
    $wagerProfile: String
    $sortBy: RaceListSort
    $filterBy: RaceListFilter
    $filterByRO: RaceListFilter
    $pageRO: Pagination
    $sortByRO: RaceListSort
  ) {
    raceDate
    mtpRaces: races(filter: $filterBy, profile: $wagerProfile, sort: $sortBy) {
      id
      tvgRaceId
      track {
        id
        code
        featured
        name
      }
      trackCode
      trackName
      number
      mtp
      postTime
      status {
        code
      }
      video {
        liveStreaming
        streams
        onTvg
        onTvg2
        hasReplay
      }
    }
    resultsRaces: races(
      filter: $filterByRO
      sort: $sortByRO
      page: $pageRO
      profile: $wagerProfile
    ) {
      id
      tvgRaceId
      mtp
      status {
        code
      }
    }
  }
`;

export default GET_RACES_MTP_STATUS;
