import { Dispatch } from "redux";
import { History } from "@tvg/ts-types/History";
import { get } from "lodash";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import {
  setUserFavoriteTracks,
  setUserFavoriteTracksWithId,
  clearUserFavoriteTracks,
  setUserFavoriteTrack,
  removeFavoriteTrack
} from "../../store/actions/favorites";
import {
  updateUserInfo,
  setUserLoggedState,
  resetUserInfo
} from "../../store/actions/user";
import { setFromQuickLinks } from "../../store/actions/app";
import { UserData } from "../../store/actions/types";
import {
  NewFavoriteTracksPayload,
  RouterPayload,
  UpdateSessionFavoriteTracksPayload
} from "../types";

export const updateTVG4Router =
  (dispatch: Dispatch) => (data: RouterPayload) => {
    mediatorClassic.dispatch("TVG4_NAVIGATION", data.payload);
    dispatch(setFromQuickLinks(!!data.payload.fromQuickLinks));
  };

export const updateRouter =
  (history: History, todaysTracks: string[]) => () => {
    setTimeout(() => {
      const pathWithParameters = window.location.href.replace(
        window.location.origin,
        ""
      );

      let pathToReplace = pathWithParameters;

      if (
        pathWithParameters.match(/^\/racetracks\/.*\/.*\?race=[0-9]*/) &&
        todaysTracks.length
      ) {
        const pathSplit = pathWithParameters.split("/");
        const searchTrackAbbr = pathSplit[2];
        const searchTrackName = pathSplit[3].substring(
          0,
          pathSplit[3].lastIndexOf("?")
        );
        const searchRaceNumber = get(
          pathWithParameters.match(/race=([^&#]*)/),
          "[1]",
          0
        );
        const trackExistsToday = todaysTracks.find(
          (race) => race === `${searchTrackAbbr}-${searchRaceNumber}`
        );

        if (!trackExistsToday) {
          pathToReplace = `/racetracks/${searchTrackAbbr}/${searchTrackName}`;
        }
      }

      if (typeof history.replace === "function") {
        history.replace(pathToReplace);
      }
    }, 0);
  };

export const updateUserFavoriteTracks =
  (dispatch: Dispatch) => (data: NewFavoriteTracksPayload) => {
    const { from, favoriteTracks, favoriteTracksWithId } = data;
    if (Array.isArray(favoriteTracks) && favoriteTracksWithId) {
      dispatch(setUserFavoriteTracks(favoriteTracks));
      dispatch(setUserFavoriteTracksWithId(favoriteTracksWithId));
    } else if (from === "home" && typeof favoriteTracks === "string") {
      mediatorClassic.dispatch("NEW_FAVORITE_TRACKS", {
        favoriteTracks: favoriteTracks.split(",")
      });
    }
  };

export const updateFavoriteTrack =
  (dispatch: Dispatch) => (data: UpdateSessionFavoriteTracksPayload) => {
    const { isAddingFavorite, trackCode, favoriteId } = data.payload;
    if (isAddingFavorite) {
      dispatch(setUserFavoriteTrack(trackCode, favoriteId));
    } else {
      dispatch(removeFavoriteTrack(trackCode));
    }
  };

export const fetchUserData = (dispatch: Dispatch) => (data: UserData) => {
  if (data.logged && data.user) {
    dispatch(updateUserInfo(data.user));
    dispatch(setUserLoggedState(true));
  } else {
    dispatch(setUserLoggedState(false));
    dispatch(resetUserInfo());
    dispatch(clearUserFavoriteTracks());
  }
};
