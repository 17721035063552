import { BettingInterest, Willpay } from "@tvg/ts-types/Race";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";

export const emptyWillPays = {
  title: "No will pays available",
  description:
    "There are no available will pays at the moment. Check back later"
};

export const willPays: Willpay[] = [
  {
    wagerAmount: 2,
    type: {
      id: 310,
      code: WagerTypeCodesEnum.DAILY_DOUBLE,
      name: "Daily Double"
    },
    payouts: [
      {
        bettingInterestNumber: 1,
        payoutAmount: 37.6
      },
      {
        bettingInterestNumber: 2,
        payoutAmount: 84.4
      },
      {
        bettingInterestNumber: 3,
        payoutAmount: 95
      }
    ],
    legResults: [
      {
        legNumber: 1,
        winningBi: 6
      }
    ]
  },
  {
    wagerAmount: 0.5,
    type: {
      id: 330,
      code: WagerTypeCodesEnum.PICK_3,
      name: "Pick 3"
    },
    payouts: [
      {
        bettingInterestNumber: 4,
        payoutAmount: 10
      },
      {
        bettingInterestNumber: 3,
        payoutAmount: 84.4
      },
      {
        bettingInterestNumber: 2,
        payoutAmount: 50
      },
      {
        bettingInterestNumber: 1,
        payoutAmount: 25
      }
    ],
    legResults: [
      {
        legNumber: 1,
        winningBi: 4
      },
      {
        legNumber: 2,
        winningBi: 5
      }
    ]
  }
];

export const bettingInterests: BettingInterest[] = [
  {
    biNumber: 1,
    isFavorite: true,
    currentOdds: {},
    morningLineOdds: {},
    runners: [
      {
        runnerId: "1",
        scratched: true,
        horseName: "Asdrubal",
        jockey: "Mickey",
        trainer: "Trainer"
      },
      {
        runnerId: "1A",
        scratched: false,
        horseName: "Asdrubal",
        jockey: "Mickey",
        trainer: "Trainer"
      }
    ],
    numberColor: "#FFFFFF",
    saddleColor: "#BC0000"
  },
  {
    biNumber: 2,
    isFavorite: false,
    currentOdds: {},
    morningLineOdds: {},
    runners: [
      {
        runnerId: "2",
        scratched: true,
        horseName: "Asdrubal2",
        jockey: "Mickey2",
        trainer: "Trainer2"
      }
    ],
    numberColor: "#FFFFFF",
    saddleColor: "#253B84"
  },
  {
    biNumber: 3,
    isFavorite: false,
    currentOdds: {},
    morningLineOdds: {},
    runners: [
      {
        runnerId: "3",
        scratched: false,
        horseName: "Asdrubal3",
        jockey: "Mickey3",
        trainer: "Trainer3"
      }
    ],
    numberColor: "#050303",
    saddleColor: "#7e2584"
  }
];
