// @flow

import styled, { css } from "styled-components";
import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.div`
  width: 100%;
  height: 96px;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const TrackInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 12px;
`;

export const FavoriteIconWrapper = styled.button`
  width: 16px;
  height: 24px;
  padding: 0;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

export const TrackName = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: 14px;
  font-weight: 500;
  height: 24px;

  > :first-child {
    width: 100%;
  }
`;

export const RaceList = styled.ul`
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  position: relative;
  overflow: ${(props) => (props.device === "desktop" ? "hidden" : "auto")};
  padding-bottom: 12px;

  li:first-child {
    padding-left: 0;
    ${(props) =>
      props.device === "desktop"
        ? css`
            margin-left: ${props.hasNavigation ? "8px" : 0};
          `
        : css`
            margin-left: 12px;
          `};
  }

  li:last-child {
    ${(props) =>
      props.device === "desktop"
        ? css`
            min-width: 100px;
            padding-right: 8px;
          `
        : css`
            min-width: 104px;
            padding-right: 12px;
          `};
  }

  li {
    margin-left: 8px;
  }

  ${(props) =>
    props.hasLineBreak &&
    css`
      flex-flow: wrap;

      li,
      li:first-child {
        margin-left: 8px;
        margin-bottom: 8px;
      }
    `}
`;

export const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 12px;
`;

export const PaginationWrapper = styled.div`
  margin: 0;
  background: ${buildColor("blue_accent", "000")};
  border-radius: 4px;
  border: 1px solid ${buildColor("blue", "100")};
  height: 48px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  & > button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
    background-color: ${buildColor("blue_accent", "000")};

    svg {
      fill: ${buildColor("blue_accent", "500")};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: ${buildColor("blue_accent", "100")};
    }
  }

  > :first-child {
    display: flex;
  }
`;

export const ResultedTag = styled.span`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  letter-spacing: 0;
`;
