import { UserActions } from "../../actions/user";
import { UserState } from "../types";

export const initialState: UserState = {
  logged: false,
  user: {
    accountNumber: "",
    emailAddress: "",
    firstName: "",
    homeAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      stateAbbr: ""
    },
    lastName: "",
    mailingAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      stateAbbr: ""
    },
    primaryPhone: "",
    profile: "PORT-Generic",
    signalProviderId: "",
    status: "",
    transportId: "",
    userName: "",
    wagerStatus: 0,
    accountRoles: []
  }
};

const userDataReducer = (
  state: UserState = initialState,
  action: UserActions
) => {
  switch (action.type) {
    case "USER_LOGGED_STATE": {
      return { ...state, logged: action.payload.isLogged };
    }
    case "USER_INFO_UPDATE": {
      return { ...state, user: { ...action.payload.user } };
    }
    case "USER_INFO_RESET": {
      return { ...state, user: { ...initialState.user } };
    }
    default: {
      return state;
    }
  }
};

export default userDataReducer;
