// @flow
import React, { Component } from "react";
import mediator from "@tvg/mediator";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import withRouter from "@tvg/utils/withCustomRouter";
import { get, isEqual, noop, flowRight as compose } from "lodash";
import RacesContainer from "@tvg/atomic-ui/_templates/RacesContainer";
import type { RaceTemplate } from "@tvg/types/Race";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import TracksGraphQuery from "./graphql/queries/TracksGraph";
import TracksRDA from "./graphql/queries/TracksRDA";
import ApolloOptions from "./graphql/options.graph";
import TracksComponents from "../../TracksType/types";
import type { AppState, Props } from "./types";

export class TracksRaces extends Component<Props> {
  static defaultProps = {
    idPrefix: "",
    wagerProfile: "PORT-Generic",
    trackType: TracksComponents.TOP_TRACKS,
    trackCode: "",
    trackName: "",
    raceList: [],
    shouldUpdate: false,
    isDisplayingRaces: false,
    raceTypeFilters: [],
    regionFilters: [],
    isGreyhoundsTrack: false,
    raceIndexScroll: 1,
    isLoading: true,
    isTalentPicksContext: false,
    hasPromoBadge: false,
    onTalentPicksModalToggle: noop,
    optedInPromos: {},
    device: "mobile",
    isFavorite: false,
    isLogged: false,
    rdaClient: null,
    isOpen: false,
    isSEORacetracks: false,
    onEmptyActionClick: noop,
    useIsPromoTagShownFlag: false,
    history: noop
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      !isEqual(nextProps.raceTypeFilters, this.props.raceTypeFilters) ||
      !isEqual(nextProps.regionFilters, this.props.regionFilters) ||
      !isEqual(this.props.optedInPromos, nextProps.optedInPromos) ||
      !isEqual(this.props.raceList, nextProps.raceList) ||
      !isEqual(nextProps.raceIndexScroll, this.props.raceIndexScroll) ||
      nextProps.shouldUpdate
    );
  }

  onRaceSelect = (
    url: string,
    trackName: string,
    race: RaceTemplate,
    flags: string,
    isFavorite: boolean,
    isGreyhound: boolean
  ) => {
    const { history } = this.props;

    if (this.props.isSEORacetracks) {
      mediator.base.dispatch({
        type: "RACETRACKS:RACE_CLICK",
        payload: {
          trackName,
          raceNumber: get(race, "number"),
          mtp: get(race, "mtp"),
          destinationUrl: window.location.origin + url
        }
      });
    } else {
      mediator.base.dispatch({
        type: "TOP_TRACKS:GO_TO_RACE",
        payload: {
          url: window.location.origin + url,
          trackName,
          race,
          flags,
          isFavorite,
          isGreyhound,
          module: this.props.trackType
        }
      });
    }

    if (this.props.device === "desktop") {
      if (this.props.isSEORacetracks && this.props.onRaceClickSEORaceTracks) {
        this.props.onRaceClickSEORaceTracks(url);
      }

      if (isTvg5()) {
        history.push(url);
      } else {
        mediator.base.dispatch({
          type: "TVG4_NAVIGATION",
          payload: { route: url }
        });
      }
    }
  };

  sendGTMScrollEvent = (scrolledBack: boolean) => {
    mediator.base.dispatch({
      type: "TOP_TRACKS_SCROLL_INTERACTION",
      payload: {
        direction: scrolledBack ? "Back" : "Next",
        module: this.props.trackType,
        isGreyhound: this.props.isGreyhoundsTrack
      }
    });
  };

  render() {
    return (
      <RacesContainer
        isLogged={this.props.isLogged}
        idPrefix={this.props.idPrefix}
        isDisplayingRaces={this.props.isDisplayingRaces}
        raceList={this.props.raceList}
        trackCode={this.props.trackCode}
        trackName={this.props.trackName}
        raceIndexScroll={this.props.raceIndexScroll}
        isGreyhound={this.props.isGreyhoundsTrack}
        isLoading={this.props.isLoading}
        sendGTMScrollEvent={this.sendGTMScrollEvent}
        onRaceSelect={this.onRaceSelect}
        isTalentPicksContext={this.props.isTalentPicksContext}
        hasPromoBadge={this.props.hasPromoBadge}
        optedInPromos={this.props.optedInPromos}
        hasNewLayout
        device={this.props.device}
        isFavorite={this.props.isFavorite}
        isOpen={this.props.isOpen}
        isSEORacetracks={this.props.isSEORacetracks}
        onEmptyActionClick={this.props.onEmptyActionClick}
        useIsPromoTagShownFlag={this.props.useIsPromoTagShownFlag}
      />
    );
  }
}

export const mapStateToProps = (store: AppState) => ({
  hasPromoBadge: get(store, "capi.featureToggles.promosDisplayBadge", false),
  wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
  raceTypeFilters: get(store, "raceFilters.raceTypeFilters"),
  regionFilters: get(store, "raceFilters.regionFilters"),
  useIsPromoTagShownFlag: get(
    store,
    "capi.featureToggles.useIsPromoTagShownFlag",
    false
  )
});

export const skipGraphQuery = ({
  isAccountCompliant,
  shouldUpdate
}: {
  isAccountCompliant: boolean,
  shouldUpdate: boolean
}) => isAccountCompliant || !shouldUpdate;
export const skipRDAQuery = ({
  isAccountCompliant,
  shouldUpdate,
  accountId
}: {
  isAccountCompliant: boolean,
  shouldUpdate: boolean,
  accountId: number
}) => !isAccountCompliant || !shouldUpdate || !accountId;

export default withRouter(
  connect(mapStateToProps, () => ({}))(
    compose(
      graphql(TracksGraphQuery, {
        ...ApolloOptions,
        skip: skipGraphQuery
      }),
      graphql(TracksRDA, {
        ...ApolloOptions,
        skip: skipRDAQuery
      }),
      connect(
        /* istanbul ignore next */
        (store) => ({
          optedInPromos: get(store, "userData.optedInPromos", {}),
          isAccountCompliant: isAccountCompliantSelector(store)
        }),
        /* istanbul ignore next */
        () => ({})
      )
    )(TracksRaces)
  )
);
