import { FavoriteTrackWithId } from "@tvg/ts-types/User";

export type SetUserFavoriteTracks = {
  type: "SET_USER_FAVORITE_TRACKS";
  payload: {
    trackCode?: string;
    favoriteTracks: string[];
  };
};

export type ClearUserFavoriteTracks = {
  type: "CLEAR_USER_FAVORITE_TRACKS";
};

export type SetUserFavoriteTrack = {
  type: "SET_FAVORITE_TRACK";
  payload: {
    trackCode: string;
    favoriteId: number;
  };
};

export type SetUserFavoriteTracksWithIdActionType = {
  type: "SET_USER_FAVORITE_TRACKS_WITH_FAVORITE_ID";
  payload: {
    favoriteTracksWithId: FavoriteTrackWithId;
  };
};

export type RemoveFavoriteTrackActionType = {
  type: "REMOVE_FAVORITE_TRACK";
  payload: {
    trackCode: string;
  };
};

export type Actions =
  | SetUserFavoriteTracks
  | SetUserFavoriteTrack
  | ClearUserFavoriteTracks
  | SetUserFavoriteTracksWithIdActionType
  | RemoveFavoriteTrackActionType;

export const setUserFavoriteTracks = (
  favoriteTracks: string[]
): SetUserFavoriteTracks => ({
  type: "SET_USER_FAVORITE_TRACKS",
  payload: { favoriteTracks }
});

export const setUserFavoriteTrack = (
  trackCode: string,
  favoriteId: number
): SetUserFavoriteTrack => ({
  type: "SET_FAVORITE_TRACK",
  payload: { trackCode, favoriteId }
});

export const setUserFavoriteTracksWithId = (
  favoriteTracksWithId: FavoriteTrackWithId
): SetUserFavoriteTracksWithIdActionType => ({
  type: "SET_USER_FAVORITE_TRACKS_WITH_FAVORITE_ID",
  payload: { favoriteTracksWithId }
});

export const removeFavoriteTrack = (
  trackCode: string
): RemoveFavoriteTrackActionType => ({
  type: "REMOVE_FAVORITE_TRACK",
  payload: { trackCode }
});

export const clearUserFavoriteTracks = (): ClearUserFavoriteTracks => ({
  type: "CLEAR_USER_FAVORITE_TRACKS"
});

export default setUserFavoriteTracks;
