import { AppState } from "../types";
import { AppActions } from "../../actions/app";

export const initialState: AppState = {
  seoTrackList: [],
  fromQuickLinks: false,
  todaysTracks: []
};

const appReducer = (state: AppState = initialState, action: AppActions) => {
  switch (action.type) {
    case "SET_SEO_TRACK_LIST": {
      return { ...state, seoTrackList: action.payload };
    }
    case "SET_FROM_QUICK_LINKS": {
      return { ...state, fromQuickLinks: action.payload };
    }
    case "SET_TODAYS_TRACKS": {
      return { ...state, todaysTracks: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
