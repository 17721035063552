import React, { ReactNode } from "react";
import { PageHeader, Icon, Button } from "@tvg/design-system";
import {
  Wrapper,
  Header,
  PageHeaderContainer,
  SearchHeaderContainer,
  CustomSearchContainer,
  CustomSearchIcon,
  CustomSearchInput,
  CustomClearButton,
  CancelSearchContainer,
  SearchInputContainer,
  Content
} from "./styled-components";

interface Props {
  children: ReactNode;
  renderHeader: boolean;
  isDesktop?: boolean;
  onBackClick?: () => void;
  enableSeoRaceTracksSearch: boolean;
  onCancelSearch: () => void;
  onBlurSearch?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  onFocusSearch?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  setSearchQuery: (searchQuery: string) => void;
  searchQuery: string;
  isLoading?: boolean;
  showPageHeader: boolean;
  showCancelSearch: boolean;
  searchInputRef: React.RefObject<HTMLInputElement>;
}

const FullTrackList = (props: Props) => {
  const renderSearchInput = () => (
    <>
      <CustomSearchContainer>
        <CustomSearchIcon>
          <Icon name="search" size="m" />
        </CustomSearchIcon>
        <CustomSearchInput
          ref={props.searchInputRef}
          placeholder="Search for a track or location..."
          value={props.searchQuery}
          // @ts-ignore
          onChange={(e) => props.setSearchQuery(e.target.value)}
          onFocus={props.onFocusSearch}
          onBlur={props.onBlurSearch}
        />
        <CustomClearButton
          isVisible={!!props.searchQuery}
          onClick={() => {
            props.setSearchQuery("");
            props.searchInputRef.current?.focus();
          }}
        >
          <Icon name="close" size="s" />
        </CustomClearButton>
      </CustomSearchContainer>
      {!props.isDesktop && (
        <CancelSearchContainer isVisible={props.showCancelSearch}>
          <Button
            variant="tertiary"
            qaLabel=""
            // @ts-ignore
            onPress={() => {
              props.onCancelSearch();
            }}
          >
            Cancel
          </Button>
        </CancelSearchContainer>
      )}
    </>
  );

  return (
    <Wrapper
      hasMinHeight={!props.isDesktop}
      hasPaddingBottom={!!props.isDesktop}
      isLoading={!!props.isLoading}
    >
      {props.renderHeader && (
        <Header
          className="track-list-header"
          hasDesktopGap={!!props.isDesktop}
          isLoading={!!props.isLoading}
        >
          <PageHeaderContainer isVisible={props.showPageHeader}>
            <PageHeader
              title="Track Information List"
              subtitle="Details and info for every track we race"
              onBack={props.onBackClick}
            />
            {props.enableSeoRaceTracksSearch && props.isDesktop && (
              <SearchInputContainer>{renderSearchInput()}</SearchInputContainer>
            )}
          </PageHeaderContainer>
          {props.enableSeoRaceTracksSearch && !props.isDesktop && (
            <SearchHeaderContainer>{renderSearchInput()}</SearchHeaderContainer>
          )}
        </Header>
      )}
      <Content>{props.children}</Content>
    </Wrapper>
  );
};

export default FullTrackList;
