// @flow
import { get, isArray } from "lodash";
import type { WagerProfile } from "@tvg/types/User";
import type { RaceInfoTracks } from "@tvg/types/Track";
import type { RaceTemplate } from "@tvg/types/Race";
import tvgConf from "@tvg/conf";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import type {
  TracksAzRacesTemplateData,
  GraphTracksAZRacesResults,
  Props
} from "../types";
import raceIndexScroll from "../helpers/raceIndexScroll";
import Tracks from "../../../TracksType/types";

const INCLUDE_GREYHOUNDS = true;

const DEFAULT_POLL_INTERVAL = 30000;

const QUERY_VARIABLES = (
  trackCode: string = "",
  wagerProfile: WagerProfile = "PORT-Generic",
  accountId: number
) => ({
  wagerProfile: wagerProfile || getPortByBrand(),
  sortByRaceNumber: {
    byRaceNumber: "ASC"
  },
  raceFilters: {
    allRaceClasses: INCLUDE_GREYHOUNDS,
    trackCode: trackCode || undefined
  },
  trackFilters: {
    code: trackCode || undefined
  },
  accountId,
  product: tvgConf().product,
  brand: tvgConf().brand
});

const raceInResults = (race: RaceInfoTracks): boolean =>
  ["RO", "MC"].indexOf(get(race, "status.code", "")) > -1;

const trackRaces = (
  races: RaceInfoTracks[] = [],
  isGreyhound: boolean = false,
  props: Props
): RaceTemplate[] =>
  races.map((race: RaceInfoTracks): RaceTemplate => {
    const sortedPromos = [...get(race, "promos", [])].sort(
      (a, b) => +b.isAboveTheLine - +a.isAboveTheLine
    );

    return {
      id: `${props.trackCode}-${race.number}`,
      trackName: props.trackName,
      trackCode: props.trackCode,
      number: race.number,
      raceStatus: get(race, "status.code", ""),
      hasBets: false,
      promo: sortedPromos
        ? {
            ...get(sortedPromos, "[0]", {}),
            promoPath: "",
            isOptedIn: false
          }
        : null,
      promos: sortedPromos,
      userPromotions: race.userPromotions || [],
      hasResults: raceInResults(race),
      postTime: race.postTime,
      mtp: race.mtp,
      isGreyhound,
      showTrackCode: false
    };
  });

export default {
  options: (props: Props) => {
    const accountId = +get(props, "accountId", "-1");

    const variables = QUERY_VARIABLES(
      props.trackCode,
      props.wagerProfile,
      accountId
    );
    if (
      props.trackType === Tracks.TRACKS_AZ ||
      props.trackType === Tracks.FAVORITE_TRACKS
    ) {
      if (props.raceTypeFilters && props.raceTypeFilters.length > 0) {
        variables.raceFilters = {
          ...variables.raceFilters,
          typeCode: props.raceTypeFilters
        };
      }

      if (isArray(props.regionFilters)) {
        if (
          props.regionFilters.indexOf("USA") >= 0 &&
          props.regionFilters.indexOf("INT") < 0
        ) {
          variables.raceFilters = {
            ...variables.raceFilters,
            includeCountries: ["USA"]
          };
        }

        if (
          props.regionFilters.indexOf("USA") < 0 &&
          props.regionFilters.indexOf("INT") >= 0
        ) {
          variables.raceFilters = {
            ...variables.raceFilters,
            excludeCountries: ["USA"]
          };
        }
      }
    }

    if (
      props.trackType === Tracks.RESULTED_FAVORITE_TRACKS ||
      props.trackType === Tracks.RESULTED_TRACKS_AZ
    ) {
      variables.raceFilters = {
        ...variables.raceFilters,
        status: ["RO", "MC", "C"]
      };
    }
    return {
      client:
        props.isAccountCompliant && !!get(props, "accountId", "")
          ? props.rdaClient
          : null,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  // $FlowFixMe
  props: (result: GraphTracksAZRacesResults): TracksAzRacesTemplateData => {
    const isGreyhound = get(result, "data.tracks[0].isGreyhound", false);
    const races = get(result, "data.races")
      ? get(result, "data.races", [])
      : get(result, "data.tracks[0].races", []);
    if (races.length > 0) {
      result.data.startPolling(DEFAULT_POLL_INTERVAL);
    }
    return {
      isGreyhoundsTrack: isGreyhound,
      raceList: trackRaces(races, isGreyhound, result.ownProps),
      raceIndexScroll: raceIndexScroll(races),
      isLoading: result.data.loading
    };
  }
};
