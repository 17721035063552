import React from "react";
import { get, noop, isEmpty } from "lodash";
import { graphql } from "@apollo/client/react/hoc";
import RaceList from "@tvg/tracks-builder/src/components/track-races";
import IndividualTrackInfo from "../IndividualTrackInfo";
import TrackListQuery from "../../../../graphql/queries/TracksListSeo";
import ApolloOptionsTL from "../../../../graphql/options.graph";
import { TrackInfoChild as Props } from "../../../../types";

export const TrackInfoChild = (props: Props) => (
  <IndividualTrackInfo
    isLoading={isEmpty(props.trackInfo)}
    isLoadingContent={props.isLoadingContent}
    content={props.content}
    device={props.device}
    isFavorite={props.isFavorite}
    trackTitle={get(props.trackInfo, "name", "")}
    trackSubtitle={props.trackSubtitle}
    imagePath={get(props.trackInfo, "imageName", "")}
    imageLink={get(props.trackInfo, "imageLink", "")}
    trackLocation={props.trackLocation}
    onBackClick={props.onBackClick}
    handleFavoriteChange={props.handleToggleFavorite}
  >
    <RaceList
      device={props.device}
      idPrefix="TOP_TRACKS"
      trackCode={props.trackInfo?.code}
      trackName={props.trackInfo?.name}
      isFavorite={props.isFavorite}
      accountId={props.accountNumber}
      isLogged={props.isLogged}
      rdaClient={props.rdaClient}
      onEmptyActionClick={props.onEmptyActionClick}
      onRaceClickSEORaceTracks={props.onRaceClickSEORaceTracks}
      shouldUpdate
      isDisplayingRaces
      isOpen
      isSEORacetracks
    />
  </IndividualTrackInfo>
);

TrackInfoChild.defaultProps = {
  dispatch: noop,
  content: "",
  device: "mobile",
  trackInfo: {},
  isLoadingContent: true,
  isFavorite: false,
  trackSubtitle: "",
  trackLocation: "",
  accountNumber: "",
  isLogged: false,
  rdaClient: null,
  fcpClient: null,
  onEmptyActionClick: noop,
  onBackClick: noop,
  handleToggleFavorite: noop,
  setShouldUpdate: noop,
  setTracksData: noop,
  shouldUpdate: false,
  wagerProfile: "PORT-Generic"
};

// @ts-ignore
export default graphql(TrackListQuery, ApolloOptionsTL)(TrackInfoChild);
