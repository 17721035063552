import { get } from "lodash";
import { Race } from "@tvg/ts-types/Race";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import {
  DerbyEventStatus,
  HomepageHeaderBanner,
  HomepageEventBannerText
} from "@urp/derby-banner/src/types";
import { Store } from "@reduxjs/toolkit";
import {
  TalentPickBet,
  EmptyMessage,
  HandicappingModuleMessage,
  InlinePPMessage,
  RepeatBetConfig,
  WillPay,
  ForbiddentBettingStates,
  RunnerModifierEnum,
  TrackListMessage
} from "../types";
import { ApplicationState } from "../../../../apps/frontend-desk/src/shared/store/types";
import { emptyProbable } from "../../__mocks__/probablesMocks";
import { emptyWillPays } from "../../__mocks__/willPaysMocks";
import { inlinePPContent } from "../../__mocks__/inlinePastPerformanceMocks";
import {
  handicappingMessageDefault,
  handicappingRunnerFlags,
  customAmountMessages,
  forbiddenBettingStates,
  betslipAccountErrorDefault,
  defaultTvg5BlacklistedBets,
  customTrackListMessages
} from "./defaultValues";

export const getBlacklistedWagerTypes = (store: unknown) =>
  parseCAPIMessage(
    store,
    "capi.messages.Tvg5BlacklistedBets",
    defaultTvg5BlacklistedBets
  );

export const getIsUserVerified = (store: Store) =>
  get(store, "userData.isVerified", false);

export const getHasRequestedLogin = (store: Store) =>
  get(store, "userData.hasRequested", false);

export const getOptedInPromosStore = (store: unknown) =>
  get(store, "userData.optedInPromos", {});

export const getProgramPageMessages = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.TVG5ProgramPage", "");

export const getBetSlipDisabledSelectionsLabel = (store: unknown) =>
  parseCAPIMessage(
    store,
    "capi.messages.BetSlipDisabledSelectionsLabel",
    "Select Runners"
  );

export const getPickSelectorLabels = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.pickSelectorLabels", undefined);

export const getOldPromosURLs = (store: unknown): string[] =>
  parseCAPIMessage(store, "capi.messages.oldPromosURLs", []);

export const getWtxErrorMessagesWithBehaviour = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.wtxErrorMessagesWithBehaviour", {});

export const getBetConfirmationMessages = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.betConfirmation", {});

export const getShowHandicappingOptionPicksToggle = (store: unknown) =>
  get(store, "capi.featureToggles.ShowHandicappingOptionPicks", false);

export const getShowBetConfirmPref = (store: ApplicationState) =>
  get(store, "userData.preferences.show_bet_confirm");

export const getGeoLocation = (store: ApplicationState) =>
  get(store, "geolocation");

export const getMyBetsEmptyStateMessage = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.mybetsEmptyStateMessage", {});

export const getPoolsEmptyStateMessage = (store: ApplicationState) =>
  parseCAPIMessage(store, "capi.messages.poolsEmptyStateMessage", {});

export const getUserBalanceIsShown = (store: ApplicationState) =>
  get(store, "userData.preferences.balance_is_shown");

export const getUserPreferences = (store: ApplicationState) =>
  get(store, "userData.preferences");

export const getHandicappingTagsDescription = (store: unknown) =>
  parseCAPIMessage(
    store,
    "capi.messages.handicappingRunnerFlags",
    handicappingRunnerFlags
  );

export const getProgramPageModuleConfigs = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.tvg5ProgramPageModulesConfig", {
    activeBetsModule: false,
    changesModule: false,
    probablesModule: false,
    poolsModule: false,
    willPaysModule: false,
    handicappingModule: false
  });

export const getChangesEmptyStateMessage = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.changesEmptyStateMessage", {});

export const getProbablesEmptyMessage = (store: Store): EmptyMessage =>
  parseCAPIMessage(
    store,
    "capi.messages.probablesEmptyStateMessage",
    emptyProbable
  );

export const getWillPaysEmptyMessage = (store: Store): WillPay =>
  parseCAPIMessage(
    store,
    "capi.messages.willPaysEmptyStateMessage",
    emptyWillPays
  );

export const getInlinePPContent = (store: Store): InlinePPMessage =>
  parseCAPIMessage(
    store,
    "capi.messages.inlinePastPerformances",
    inlinePPContent
  );

export const getIsLoginModalOpen = (store: unknown): boolean =>
  get(store, "loginModal.loginOpen", false);

export const getIsBetSlipExpanded = (store: unknown) =>
  get(store, "app.isBetSlipExpanded", false);

export const getIsRunnerModifierExpanded = (store: unknown) =>
  get(store, "programPage.isRunnerModifierExpanded", false);

export const getIsPoolsNInfoModalOpen = (store: unknown) => ({
  isPoolsNInfoModalOpen: get(store, "programPage.isPoolsNInfoModalOpen", false),
  PIRace: get(store, "programPage.PIRace", undefined)
});

export const getBetslipAccountError = (store: Store) =>
  parseCAPIMessage(
    store,
    "capi.messages.BetslipAccountError",
    betslipAccountErrorDefault
  );

export const getHandicappingModule = (
  store: Store
): HandicappingModuleMessage =>
  parseCAPIMessage(
    store,
    "capi.messages.handicappingModule",
    handicappingMessageDefault
  );

export const getIsHamburgerMenuOpen = (store: unknown): boolean =>
  get(store, "hdr.isHamburgerMenuOpen", false);

export const getBetSelections = (store: unknown) =>
  get(store, "BetTicket.betSelections", [[]]);

export const getScratchedSelections = (store: unknown) =>
  get(store, "BetTicket.scratchedSelections", [[]]);

export const getBetConfirmModalTexts = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.betConfirmModalText", {});

export const getRepeatBetConfig = (store: Store): RepeatBetConfig =>
  parseCAPIMessage(store, "capi.messages.repeatBet", {});

export const getTalentPickBet = (store: Store): TalentPickBet =>
  parseCAPIMessage(store, "capi.messages.talentPickBet", {});

export const getTrackListMessages = (store: Store): TrackListMessage =>
  parseCAPIMessage(
    store,
    "capi.messages.trackListMessage",
    customTrackListMessages
  );

export const getCustomAmountMessages = (store: Store) =>
  parseCAPIMessage(
    store,
    "capi.messages.CustomAmountModalMessages",
    customAmountMessages
  );

export const getForbiddenBettingStates = (
  store: Store
): ForbiddentBettingStates =>
  parseCAPIMessage(
    store,
    "capi.messages.forbiddenBettingStates",
    forbiddenBettingStates
  );

export const getFeatureBettingBlockedByUserState = (store: unknown) =>
  get(store, "capi.featureToggles.bettingBlockedByUserState", false);

export const getFeatureTracksList = (store: unknown) =>
  get(store, "capi.featureToggles.showTracksListDropdown", true);

export const getUserFavoriteRunners = (store: unknown): Array<string> =>
  get(store, "programPage.favoriteRunners", []);

export const getCancelLimitBetSlipEnabled = (store: unknown) =>
  get(store, "capi.featureToggles.cancelLimitBetSlipEnabled", false);

export const getCancelLimitsBetSlipConf = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.cancelLimitsBetSlipConf", null);

export const getUserAcceptedTermsAndConditions = (store: unknown) =>
  get(store, "userData.jurisdiction.accepted", false);

export const getStoredFreePastPerformace = (
  store: Store
): Record<string, string>[] =>
  get(store, "programPage.freePastPerformances", []);

export const getFppSectionContent = (store: Store): Record<string, string> =>
  parseCAPIMessage(store, "capi.messages.fppSectionContent");

export const getEnableFppSection = (store: Store): Record<string, string> =>
  get(store, "capi.featureToggles.enableFpp", false);

export const getEnableUrpConfirmModalRepeatBet = (store: unknown) =>
  get(store, "capi.featureToggles.enableUrpConfirmModalRepeatBet", false);

export const getSelectedRunnerModifier = (store: unknown): RunnerModifierEnum =>
  get(store, "programPage.selectedRunnerModifier", RunnerModifierEnum.DEFAULT);

export const getEventDate = (store: unknown): Date | null =>
  new Date(get(store, "home.eventInfo.eventDate", null));

export const getDerbyRace = (store: unknown): Race | undefined =>
  get(store, "home.derbyRace");

export const getHomepageEventTrackAbbr = (store: unknown): string =>
  get(store, "home.eventInfo.trackAbbr", "");

export const getHomepageCurrentConfig = (store: unknown): DerbyEventStatus =>
  get(store, "home.currentConfig", DerbyEventStatus.POST);

export const getHomepageDerbyBanner = (store: unknown): HomepageHeaderBanner =>
  get(store, "home.derbyBanner", undefined);

export const getDerbyBannerImageToggle = (store: unknown) =>
  get(store, "capi.featureToggles.useDerbyBannerImage", false);

export const getHomepageEventName = (store: unknown): HomepageEventBannerText =>
  get(store, "home.eventInfo.eventName", {
    label: "",
    size: 0
  });

export const getshowStoryblokHomeToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.showStoryblokHome", false);
