import React, {
  FC,
  useState,
  SyntheticEvent,
  useMemo,
  useEffect,
  useRef
} from "react";
import type { ImageProps } from "./types";
import { StyledImage, Container } from "./styled-components";
import { Error } from "./error";

export const Image: FC<ImageProps> = ({
  source,
  width,
  height,
  isResponsive = false,
  accessibilityLabel = "",
  objectFit = "fill",
  qaLabel = "image-label",
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [sourceWidth, setSourceWidth] = useState<number>(0);
  const [, setSourceHeight] = useState<number>(0);
  const [ratio, setRatio] = useState<number>(0);
  const [error, setError] = useState<SyntheticEvent | null>(null);

  const setDimension = (w: number, h: number) => {
    setSourceWidth(w);
    setSourceHeight(h);
    setRatio(h / w);
  };

  const fetchImgDimensions = (src: string) => {
    const img: HTMLImageElement = new window.Image();
    img.src = src;
    img.onload = () => {
      setDimension(img.width, img.height);
    };
  };

  useEffect(() => {
    fetchImgDimensions(source);
  }, []);

  useEffect(() => {
    const updateWidth = (entries: ResizeObserverEntry[]) => {
      if (entries[0]) {
        const newWidth = entries[0].contentRect.width;
        setContainerWidth(newWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef.current]);

  const getWidth = () => {
    if (isResponsive) return containerWidth;
    if (!width && height) return height * ratio;
    if (!width && !height) return sourceWidth;
    return width || 0;
  };

  const imageStyles = useMemo(
    () => ({
      width: getWidth(),
      height: height || getWidth() * ratio
    }),
    [containerWidth, ratio, width, height]
  );

  const errorHandler = (err: SyntheticEvent) => {
    setError(err);
  };

  if (error) return <Error>{accessibilityLabel}</Error>;

  return (
    <Container ref={containerRef}>
      <StyledImage
        src={source}
        style={imageStyles}
        data-qa-label={qaLabel}
        onError={errorHandler}
        object-fit={objectFit}
        {...rest}
      />
    </Container>
  );
};

export type { ImageProps };
