import React, { ReactNode } from "react";
import tvgConf from "@tvg/conf";
import { noop } from "lodash";
import { PageHeader, Paragraph, Icon, buildColor } from "@tvg/design-system";
import { Image } from "@tvg/design-system/web";
import TracksMask from "@tvg/atomic-ui/_static/Masks/tracks";
import TrackPageContentMask from "@tvg/atomic-ui/_static/Masks/mybetsStandalone";
import {
  Wrapper,
  Header,
  FavoriteTrackContainer,
  TitleFavoriteButton,
  Content,
  ImageAndLocationSection,
  ImageContainer,
  UpcomingRacesSection,
  UpcomingRacesTitle,
  UpcomingRacesChildren,
  TrackDetailsContent
} from "./styled-components";

interface Props {
  children: ReactNode;
  trackTitle: string;
  trackSubtitle: string;
  imageLink: string | null;
  imagePath: string | null;
  trackLocation: string;
  content: string;
  isFavorite?: boolean;
  onBackClick: () => void;
  isLoading: boolean;
  isLoadingContent: boolean;
  device: "mobile" | "tablet" | "desktop";
  handleFavoriteChange?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const IndividualTrackInfo = (props: Props) => {
  const { gcs } = tvgConf().config("google");

  return (
    <Wrapper
      hasMinHeight={props.device !== "desktop"}
      hasPaddingBottom={props.device === "desktop"}
      isLoading={props.isLoading}
    >
      {props.isLoading ? (
        <TracksMask />
      ) : (
        <>
          <Header
            hasDesktopGap={props.device === "desktop"}
            isLoading={props.isLoading}
          >
            <PageHeader
              title={props.trackTitle}
              subtitle={props.trackSubtitle}
              onBack={props.onBackClick}
              hasShadow
            />
            <FavoriteTrackContainer
              onClick={props.handleFavoriteChange}
              isDesktop={props.device === "desktop"}
            >
              <Icon
                name="star"
                size="m"
                lineColor={props.isFavorite ? "yellow.500" : "grey.800"}
                backgroundColor={props.isFavorite ? "yellow.500" : "white.000"}
                qaLabel={props.isFavorite ? "star-checked" : "star-unchecked"}
              />
              {props.device === "desktop" && (
                <TitleFavoriteButton>
                  {props.isFavorite ? "Favorited" : "Add to Favorites"}
                </TitleFavoriteButton>
              )}
            </FavoriteTrackContainer>
          </Header>
          <Content>
            {(props.imagePath || props.trackLocation) && (
              <ImageAndLocationSection>
                {props.imagePath && props.imageLink && (
                  <ImageContainer
                    smallerSize={
                      props.device === "tablet" || props.device === "desktop"
                    }
                  >
                    <a
                      href={props.imageLink}
                      target="_blank"
                      rel="noreferrer"
                      data-qa-label="TrackInfo-ImageLink"
                    >
                      <Image
                        isResponsive
                        source={`${gcs.url}/${gcs.tvgStatic}/static/cms_files/${props.imagePath}`}
                      />
                    </a>
                  </ImageContainer>
                )}
                {props.trackLocation && (
                  <Paragraph
                    color={buildColor("grey", "700")}
                    qaLabel="TrackInfo-Location"
                  >
                    {props.trackLocation}
                  </Paragraph>
                )}
              </ImageAndLocationSection>
            )}
            <UpcomingRacesSection
              hasBiggerPaddings={props.device === "desktop"}
            >
              <UpcomingRacesTitle
                hasBiggerPaddings={props.device === "desktop"}
              >
                Racing at {props.trackTitle}
              </UpcomingRacesTitle>
              <UpcomingRacesChildren
                hasBiggerPaddings={props.device === "desktop"}
              >
                {props.children}
              </UpcomingRacesChildren>
            </UpcomingRacesSection>
            {props.isLoadingContent && <TrackPageContentMask cardAmount={1} />}
            {!!props.content && !props.isLoadingContent && (
              <TrackDetailsContent
                data-qa-label="TrackInfo-Content"
                hasBiggerPaddings={props.device === "desktop"}
                dangerouslySetInnerHTML={{ __html: props.content }}
              />
            )}
          </Content>
        </>
      )}
    </Wrapper>
  );
};

IndividualTrackInfo.defaultProps = {
  trackTitle: "",
  trackSubtitle: "",
  content: "",
  isLoading: false,
  isLoadingContent: false,
  onBackClick: noop,
  device: "mobile"
};

export default IndividualTrackInfo;
