import React from "react";
import { Header, Paragraph, Icon } from "@tvg/design-system";
import { EmptyContainer, IconContainer } from "./styled-components";

interface Props {
  hasCenterAlignment: boolean;
}

const RaceTracksEmptySearch = (props: Props) => (
  <EmptyContainer hasCenterAlignment={props.hasCenterAlignment}>
    <IconContainer>
      <Icon name="tracks" size="l" />
    </IconContainer>
    <Header tag="h2" qaLabel="EmptySearchTitle" mb="space-3">
      No Results Found
    </Header>
    <Paragraph qaLabel="EmptySearchParagraph" color="grey.800">
      The item(s) you searched could not be found.
    </Paragraph>
  </EmptyContainer>
);

RaceTracksEmptySearch.defaultProps = {
  hasCenterAlignment: false
};

export default RaceTracksEmptySearch;
