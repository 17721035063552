import type { RaceStatusEnum } from "@tvg/ts-types/Race";
import { get } from "lodash";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import Props, { QueryProps } from "../types";

const INCLUDE_GREYHOUNDS = true;

const QUERY_VARIABLES: QueryProps = {
  filterBy: {
    status: ["O", "SK", "IC"],
    allRaceClasses: INCLUDE_GREYHOUNDS
  },
  sortBy: { byPostTime: "ASC" },
  filterByRO: {
    allRaceClasses: INCLUDE_GREYHOUNDS,
    status: ["RO"]
  },
  sortByRO: { byPostTime: "DESC" },
  pageRO: { results: 30, current: 0 }
};

const POLL_INTERVAL = 20000;
const POLL_INTERVAL_SUBSCRIPTION = 0;

type GraphMtpStatusResult = {
  id: string;
  mtp: number;
  status: {
    code: RaceStatusEnum;
  };
};

type GraphMTPStatusResults = {
  mtpRaces: GraphMtpStatusResult[];
  resultsRaces: GraphMtpStatusResult[];
  raceDate: string;
};

export default {
  options: (props: Props) => {
    if (!props.removeStartIn) {
      QUERY_VARIABLES.filterBy = {
        ...QUERY_VARIABLES.filterBy,
        startIn: 60
      };
    }

    const variables = {
      ...QUERY_VARIABLES,
      wagerProfile: props.wagerProfile || getPortByBrand()
    };

    return {
      pollInterval: props.shouldRunSubscription
        ? POLL_INTERVAL_SUBSCRIPTION
        : POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result: GraphMTPStatusResults) => ({
    mtpRaces: [
      ...get(result, "data.mtpRaces", []),
      ...get(result, "data.resultsRaces", [])
    ],
    raceDate: get(result, "data.raceDate", "")
  })
};
