export const winProbable = {
  wagerType: {
    code: "WN",
    id: 10
  },
  minWagerAmount: 1,
  betCombos: [
    {
      runner1: "1",
      runner2: null,
      payout: "1.32"
    },
    {
      runner1: "2",
      runner2: null,
      payout: "4.82"
    },
    {
      runner1: "3",
      runner2: null,
      payout: "2.92"
    },
    {
      runner1: "4",
      runner2: null,
      payout: "1.1"
    },
    {
      runner1: "5",
      runner2: null,
      payout: "1.85"
    },
    {
      runner1: "6",
      runner2: null,
      payout: "2.5"
    },
    {
      runner1: "7",
      runner2: null,
      payout: "11.2"
    },
    {
      runner1: "8",
      runner2: null,
      payout: "2.1"
    },
    {
      runner1: "9",
      runner2: null,
      payout: "3.2"
    }
  ]
};

export const placeProbable = {
  wagerType: {
    id: 20,
    code: "PL"
  },
  minWagerAmount: 1,
  betCombos: [
    {
      runner1: "1",
      runner2: "1",
      payout: "1.32"
    },
    {
      runner1: "1",
      runner2: "2",
      payout: "2.5"
    },
    {
      runner1: "1",
      runner2: "3",
      payout: "2.92"
    },
    {
      runner1: "1",
      runner2: "4",
      payout: "1.1"
    },
    {
      runner1: "2",
      runner2: "1",
      payout: "1.85"
    },
    {
      runner1: "2",
      runner2: "2",
      payout: "4.82"
    },
    {
      runner1: "2",
      runner2: "3",
      payout: "11.2"
    },
    {
      runner1: "2",
      runner2: "4",
      payout: "2.1"
    }
  ]
};

export const showProbable = {
  ...placeProbable,
  wagerType: {
    id: 30,
    code: "SH"
  }
};

export const quinellaProbable = {
  wagerType: {
    id: 80,
    code: "QN"
  },
  minWagerAmount: 1,
  betCombos: [
    {
      runner1: "1",
      runner2: "1",
      payout: "0.0"
    },
    {
      runner1: "1",
      runner2: "2",
      payout: "4.82"
    },
    {
      runner1: "1",
      runner2: "3",
      payout: "2.92"
    },
    {
      runner1: "2",
      runner2: "1",
      payout: "4.82"
    },
    {
      runner1: "2",
      runner2: "2",
      payout: "0.0"
    },
    {
      runner1: "2",
      runner2: "3",
      payout: "2.1"
    },
    {
      runner1: "3",
      runner2: "1",
      payout: "2.92"
    },
    {
      runner1: "3",
      runner2: "2",
      payout: "2.1"
    },
    {
      runner1: "3",
      runner2: "3",
      payout: "0.0"
    }
  ]
};

export const dailyDoubleProbable = {
  wagerType: {
    id: 310,
    code: "DB"
  },
  minWagerAmount: 1,
  betCombos: [
    {
      runner1: "1",
      runner2: "1",
      payout: "0.0"
    },
    {
      runner1: "1",
      runner2: "2",
      payout: "7.12"
    },
    {
      runner1: "1",
      runner2: "3",
      payout: "1.62"
    },
    {
      runner1: "2",
      runner2: "1",
      payout: "3.49"
    },
    {
      runner1: "2",
      runner2: "2",
      payout: "0.0"
    },
    {
      runner1: "2",
      runner2: "3",
      payout: "2.9"
    },
    {
      runner1: "3",
      runner2: "1",
      payout: "9.9"
    },
    {
      runner1: "3",
      runner2: "2",
      payout: "4.1"
    },
    {
      runner1: "3",
      runner2: "3",
      payout: "0.0"
    }
  ]
};

export const exactaProbable = {
  minWagerAmount: 1,
  wagerType: {
    id: 110,
    code: "EX"
  },
  betCombos: [
    { runner1: "1", runner2: "1", payout: "0.0" },
    { runner1: "1", runner2: "2", payout: "1.08" },
    { runner1: "1", runner2: "3", payout: "1.68" },
    { runner1: "1", runner2: "4", payout: "1.18" },
    { runner1: "1", runner2: "5", payout: "3.48" },
    { runner1: "1", runner2: "6", payout: "3.94" },
    { runner1: "1", runner2: "7", payout: "0.0" },
    { runner1: "1", runner2: "8", payout: "0.0" },
    { runner1: "2", runner2: "1", payout: "2.1" },
    { runner1: "2", runner2: "2", payout: "0.0" },
    { runner1: "2", runner2: "3", payout: "5.84" },
    { runner1: "2", runner2: "4", payout: "6.66" },
    { runner1: "2", runner2: "5", payout: "5.18" },
    { runner1: "2", runner2: "6", payout: "9.82" },
    { runner1: "2", runner2: "7", payout: "0.0" },
    { runner1: "2", runner2: "8", payout: "0.0" },
    { runner1: "3", runner2: "1", payout: "3.04" },
    { runner1: "3", runner2: "2", payout: "4.7" },
    { runner1: "3", runner2: "3", payout: "0.0" },
    { runner1: "3", runner2: "4", payout: "6.88" },
    { runner1: "3", runner2: "5", payout: "10.38" },
    { runner1: "3", runner2: "6", payout: "17.0" },
    { runner1: "3", runner2: "7", payout: "0.0" },
    { runner1: "3", runner2: "8", payout: "0.0" },
    { runner1: "4", runner2: "1", payout: "2.42" },
    { runner1: "4", runner2: "2", payout: "5.1" },
    { runner1: "4", runner2: "3", payout: "6.06" },
    { runner1: "4", runner2: "4", payout: "0.0" },
    { runner1: "4", runner2: "5", payout: "11.14" },
    { runner1: "4", runner2: "6", payout: "17.4" },
    { runner1: "4", runner2: "7", payout: "0.0" },
    { runner1: "4", runner2: "8", payout: "0.0" },
    { runner1: "5", runner2: "1", payout: "8.38" },
    { runner1: "5", runner2: "2", payout: "5.06" },
    { runner1: "5", runner2: "3", payout: "12.3" },
    { runner1: "5", runner2: "4", payout: "11.66" },
    { runner1: "5", runner2: "5", payout: "0.0" },
    { runner1: "5", runner2: "6", payout: "19.78" },
    { runner1: "5", runner2: "7", payout: "0.0" },
    { runner1: "5", runner2: "8", payout: "0.0" },
    { runner1: "6", runner2: "1", payout: "5.86" },
    { runner1: "6", runner2: "2", payout: "11.26" },
    { runner1: "6", runner2: "3", payout: "19.64" },
    { runner1: "6", runner2: "4", payout: "24.14" },
    { runner1: "6", runner2: "5", payout: "19.74" },
    { runner1: "6", runner2: "6", payout: "0.0" },
    { runner1: "6", runner2: "7", payout: "0.0" },
    { runner1: "6", runner2: "8", payout: "0.0" },
    { runner1: "7", runner2: "1", payout: "0.0" },
    { runner1: "7", runner2: "2", payout: "0.0" },
    { runner1: "7", runner2: "3", payout: "0.0" },
    { runner1: "7", runner2: "4", payout: "0.0" },
    { runner1: "7", runner2: "5", payout: "0.0" },
    { runner1: "7", runner2: "6", payout: "0.0" },
    { runner1: "7", runner2: "7", payout: "0.0" },
    { runner1: "7", runner2: "8", payout: "0.0" },
    { runner1: "8", runner2: "1", payout: "0.0" },
    { runner1: "8", runner2: "2", payout: "0.0" },
    { runner1: "8", runner2: "3", payout: "0.0" },
    { runner1: "8", runner2: "4", payout: "0.0" },
    { runner1: "8", runner2: "5", payout: "0.0" },
    { runner1: "8", runner2: "6", payout: "0.0" },
    { runner1: "8", runner2: "7", payout: "0.0" },
    { runner1: "8", runner2: "8", payout: "0.0" }
  ]
};

export const placeNaN = {
  wagerType: {
    id: 20,
    code: "PL"
  },
  minWagerAmount: 1,
  betCombos: [
    {
      runner1: "1",
      runner2: null,
      payout: "1.32"
    },
    {
      runner1: "2",
      runner2: null,
      payout: "4.82"
    },
    {
      runner1: "3",
      runner2: null,
      payout: "2.92"
    },
    {
      runner1: "4",
      runner2: null,
      payout: "1.1"
    },
    {
      runner1: "5",
      runner2: null,
      payout: "1.85"
    },
    {
      runner1: "6",
      runner2: null,
      payout: "2.5"
    },
    {
      runner1: "7",
      runner2: null,
      payout: "11.2"
    },
    {
      runner1: "8",
      runner2: null,
      payout: "2.1"
    },
    {
      runner1: "9",
      runner2: null,
      payout: "3.2"
    }
  ]
};

export const emptyProbable = {
  title: "No Probables available",
  description:
    "There are no available Probables at the moment. Check back later"
};

export const probablesMock = [
  winProbable,
  placeProbable,
  showProbable,
  quinellaProbable,
  dailyDoubleProbable,
  exactaProbable,
  emptyProbable
];
