// @flow

import styled from "styled-components";
import { Link } from "react-router-dom";
import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled(Link)`
  width: 92px;
  height: 48px;
  padding: 6px 8px;
  background: ${buildColor("blue_accent", "000")};
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 1px 2px 0 ${buildColor("blue", "000")};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  flex-shrink: 0;

  :hover {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const RaceTime = styled.span`
  width: 100%;
  height: 16px;
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  display: block;
`;

export const RaceInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RaceNumber = styled.span`
  flex-grow: 1;
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: 14px;
  font-weight: 500;
  display: block;
`;

export const RacePromoIcon = styled.span`
  display: block;
  width: 16px;
  height: 16px;
`;
