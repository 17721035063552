// @flow

import React, { PureComponent } from "react";
import type { RaceStatusEnum } from "@tvg/types/Race";
import {
  formatPostTime,
  formatPeriod,
  formatRaceDate
} from "@tvg/formatter/dates";
import MTPTime, {
  RaceClosedStatus,
  PlainMTP,
  PlainMTPText
} from "./styled-components";

/*
 * Be careful with this flag!!!
 * TODO: This shall be refactored later
 * */
export const isRaceOff = (status: RaceStatusEnum): boolean =>
  status === "SK" || status === "RO";
type Props = {
  /**
   * MTP value ex: 3
   */
  mtp: number,
  /**
   * Posttime string value ex: 2017-10-29T09:28:00.000Z
   */
  postTime: string,
  /**
   * Status of the race
   */
  status: RaceStatusEnum,
  /**
   * Enables the layout in 2 lines
   */
  multipleLine: boolean,
  /**
   * Enables a bigger font-size
   */
  bigger: boolean,
  /**
   * Enables the dark layout
   */
  darken: boolean,
  /**
   * Whenever the mtp element should have padding or not around itself
   */
  paddingless: boolean,
  /**
   * Whenever the mtp shows the race date when mtp > 60
   */
  showRaceDate: boolean,
  /**
   * When is Highlighted the font-size is bigger
   */
  isHighlightedBellowThreshold: boolean,
  /**
   * Allows _atom to be extended
   */
  className: string,
  /**
   * isPlainMTP flag aims to decide which style of MTP to render
   * false: old MTP style
   * true: new MTP style - Top Tracks Links for example
   */
  isPlainMTP: boolean,
  /**
   * isOnBiggestWinner flag aims to decide which style of MTP to render on Biggest Winner of Previous Winners Component
   * false: MTP style
   * true: new MTP style - Text colors are different
   */
  isOnBiggestWinner: boolean,
  /**
   * isMyBets flag changes some texts and styles MyBets Standalone
   */
  isMyBets: boolean,
  /**
   * Difference of date between the current date and the postTime date
   */
  dateDiff: number,
  /**
   * isMTPNewRules flag changes MTP rules
   */
  isMTPNewRules: boolean
};

export default class MTP extends PureComponent<Props> {
  static defaultProps = {
    mtp: 0,
    postTime: new Date().toISOString(),
    status: "O",
    multipleLine: false,
    darken: false,
    bigger: false,
    paddingless: false,
    showRaceDate: false,
    isHighlightedBellowThreshold: false,
    className: "",
    isPlainMTP: false,
    isOnBiggestWinner: false,
    isMyBets: false,
    dateDiff: 0,
    isMTPNewRules: false
  };

  mtpThreshold = 60;

  mtpCloseThreshold = 10;

  renderMTP() {
    if (!isRaceOff(this.props.status)) {
      if (this.props.mtp > this.mtpThreshold) {
        return formatPostTime(this.props.postTime, this.props.showRaceDate);
      }

      return `${this.props.mtp.toString()}m`;
    }

    return (
      <RaceClosedStatus status={this.props.status}>
        {this.props.status === "RO" ? "RES" : "OFF"}
      </RaceClosedStatus>
    );
  }

  renderPlainMtp = () => {
    switch (this.props.status) {
      case "SK":
        return (
          <PlainMTPText data-qa-label="mtpRaceOff" isRaceOff>
            Race off
          </PlainMTPText>
        );
      case "RO":
        return (
          <PlainMTPText
            data-qa-label="mtpResult"
            isMyBets={this.props.isMyBets}
            isResulted
          >
            {this.props.isMyBets ? "Resulted" : "Result"}
          </PlainMTPText>
        );
      default: {
        const mtpSuffix = this.props.isMyBets ? " MTP" : "m";
        return this.props.mtp > 60
          ? `${formatPostTime(this.props.postTime, false)} ${formatPeriod(
              this.props.postTime
            )}`
          : `${this.props.mtp}${mtpSuffix}`;
      }
    }
  };

  renderRaceDate = () => (
    <PlainMTPText>{` - ${formatRaceDate(this.props.postTime)}`}</PlainMTPText>
  );

  render() {
    return this.props.isPlainMTP ? (
      <PlainMTP
        className={this.props.className}
        data-qa-label="mtp"
        postTime={this.props.postTime}
        mtp={this.props.mtp}
        isOnBiggestWinner={this.props.isOnBiggestWinner}
        isMyBets={this.props.isMyBets}
        isMTPNewRules={this.props.isMTPNewRules}
      >
        {this.renderPlainMtp()}
        {this.props.dateDiff < 0 && this.renderRaceDate()}
      </PlainMTP>
    ) : (
      <MTPTime
        data-qa-label="mtp"
        className={this.props.className}
        postTime={this.props.postTime}
        mtp={this.props.mtp}
        mtpCloseThreshold={this.mtpCloseThreshold}
        mtpThreshold={this.mtpThreshold}
        isRaceOff={isRaceOff(this.props.status)}
        multipleLine={this.props.multipleLine}
        darken={this.props.darken}
        bigger={this.props.bigger}
        paddingless={this.props.paddingless}
        showRaceDate={this.props.showRaceDate}
      >
        {this.renderMTP()}
      </MTPTime>
    );
  }
}
