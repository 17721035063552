import styled from "styled-components";

export const Container = styled.div`
  margin: 12px;
`;

export const EmptyTitle = styled.p`
  margin: 21px 0 8px 0;

  & + div {
    align-items: flex-start;
    padding-left: 0;
  }
`;
