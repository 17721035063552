import React, { useCallback } from "react";
import { throttle } from "lodash";
import { formatTrackName } from "@tvg/formatter/url";
import { Device } from "@tvg/ts-types/Device";
import TrackLineHeader from "../TrackLineHeader";
import { onTrackClickGtm } from "../../../../utils/gtm";
import useFavorite from "../../../../hooks/useFavorite";
import { TrackRowProps } from "../../../../types";

const getQaLabel = (name: string) => {
  if (name.substring(0, 2) === name.substring(0, 2).toUpperCase()) {
    return `track-${name.replace(/ /g, "")}`;
  }

  return `track-${name.replace(/ /g, "-")}`;
};

export const TrackRow = ({
  track,
  favoriteId,
  onTrackClick,
  device = Device.MOBILE,
  isLogged,
  accountNumber,
  isAccountCompliant
}: TrackRowProps) => {
  const { isFavorite, toggleFavoriteAction } = useFavorite(
    favoriteId,
    track,
    isLogged,
    accountNumber,
    device,
    isAccountCompliant
  );
  const handleToggleFavorite = useCallback(
    throttle(toggleFavoriteAction, 1500, {
      trailing: false
    }),
    [toggleFavoriteAction]
  );
  const handleTrackClick = useCallback(() => {
    const destinationUrl = `/racetracks/${track.code}/${formatTrackName(
      track.name
    )}`;
    if (typeof onTrackClick === "function") {
      onTrackClick(destinationUrl);
    }
    onTrackClickGtm(track.name, window.location.origin + destinationUrl);
  }, [track]);
  return (
    <TrackLineHeader
      qaLabel={getQaLabel(track.name)}
      trackName={track.name}
      trackCode={track.code}
      isFavorite={isFavorite}
      handleFavoriteChange={handleToggleFavorite}
      handleTrackClick={handleTrackClick}
      isSEORacetracks
    />
  );
};

export default TrackRow;
