// @flow
import styled, { css } from "styled-components";
import { fontBold, fontCondensedNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const RowLetter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${buildColor("blue_accent", "000")};
  color: ${buildColor("grey", "800")};
  letter-spacing: 0;
  ${({ isSEORacetracks }) =>
    isSEORacetracks
      ? css`
          height: 36px;
          padding: 8px 12px;
          font-family: ${fontCondensedNormal};
          font-size: 14px;
          border-top: 1px solid ${buildColor("blue", "100")};
          border-bottom: 1px solid ${buildColor("blue", "100")};
        `
      : css`
          height: 32px;
          padding: 7px 12px;
          font-family: ${fontBold};
          font-size: 15px;
          border: 1px solid ${buildColor("blue", "100")};
        `}
`;

export default RowLetter;
