import { attempt, get, isEmpty } from "lodash";
import { Dispatch } from "redux";
import ufc from "@tvg/api/ufc";
import tvgConf from "@tvg/conf";
import LoginService from "@tvg/login-service";
import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import { GetAlertsResponse } from "@tvg/ts-types/Alerts";
import { FavoriteTrackWithId } from "@tvg/ts-types/User";
import {
  Feature,
  FeatureToggles,
  FeatureOverride,
  MapFeatureToggles,
  Messages,
  UserData
} from "../../store/actions/types";
import {
  successMessagesRequest,
  failMessagesRequest,
  successFeaturesRequest,
  failFeaturesRequest,
  successSeoMessagesRequest,
  failSeoMessagesRequest
} from "../../store/actions/capi";
import {
  setUserFavoriteTracks,
  setUserFavoriteTracksWithId
} from "../../store/actions/favorites";
import { setUserLoggedState, updateUserInfo } from "../../store/actions/user";

export const getCapiMessages = (dispatch: Dispatch) => {
  const messageNamespaces = ["Homepage", "InformationalPages"];

  return tvgConf()
    .getMessages(messageNamespaces)
    .then((response: Messages) => dispatch(successMessagesRequest(response)))
    .catch((err: Error) => dispatch(failMessagesRequest(err)));
};

export const getSeoCapiMessages = (dispatch: Dispatch) => {
  const messageNamespaces = ["SeoData"];

  return tvgConf()
    .getMessages(messageNamespaces)
    .then((response: Messages) => dispatch(successSeoMessagesRequest(response)))
    .catch((err: Error) => dispatch(failSeoMessagesRequest(err)));
};

const getFeaturesOverrides = () =>
  attempt(
    () => JSON.parse(window.localStorage.getItem("featureOverrides")!),
    false
  );

const setFeaturesOverrides = (featureOverrides: FeatureOverride) =>
  attempt(() =>
    window.localStorage.setItem(
      "featureOverrides",
      JSON.stringify(featureOverrides)
    )
  );

export const setFeatureToggles = (
  features: Feature[],
  dispatch: Dispatch
): void => {
  const featureToggles: MapFeatureToggles = {};
  let featureOverrides: FeatureOverride = getFeaturesOverrides() || {};
  const hasFeaturesOverrides = !!getFeaturesOverrides();

  features.forEach((toggle: Feature) => {
    let { enabled } = toggle;
    featureOverrides = calculateFeatureOverride(
      hasFeaturesOverrides,
      featureOverrides,
      toggle
    );
    if (get(featureOverrides, toggle.name)) {
      // eslint-disable-next-line prefer-destructuring
      enabled = featureOverrides[toggle.name].enabled;
    }

    featureToggles[toggle.name] = enabled;
  });

  if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
    setFeaturesOverrides(featureOverrides);
  }
  dispatch(successFeaturesRequest(featureToggles));
};

export const getFeatures = (dispatch: Dispatch) =>
  typeof window !== "undefined" &&
  tvgConf()
    .getFeatures()
    .then((response: FeatureToggles | false) => {
      const featureToggles: MapFeatureToggles = {};
      let featureOverrides = getFeaturesOverrides() || {};
      const hasFeaturesOverrides = !!getFeaturesOverrides();

      if (response && Array.isArray(response.featureToggles)) {
        response.featureToggles.forEach((toggle: Feature) => {
          let { enabled } = toggle;
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );

          if (get(featureOverrides, toggle.name)) {
            // eslint-disable-next-line prefer-destructuring
            enabled = featureOverrides[toggle.name].enabled;
          }

          featureToggles[toggle.name] = enabled;
        });
      }

      if (!isEmpty(featureOverrides)) {
        setFeaturesOverrides(featureOverrides);
      }

      dispatch(successFeaturesRequest(featureToggles));
    })
    .catch((err: Error) => dispatch(failFeaturesRequest(err)));

export const getFavoriteTracks = (accountNumber: string, dispatch: Dispatch) =>
  typeof window !== "undefined" &&
  ufc
    .getUserAlerts(accountNumber, "track")
    .then((response: { data: GetAlertsResponse }) => {
      const userFavorites: FavoriteTrackWithId = {};
      const ufcFavoriteTracks = response.data.favorites.filter(
        (favorite) => !favorite.subscriptions.length
      );
      ufcFavoriteTracks.forEach((ufcFavorite) => {
        userFavorites[ufcFavorite.entityName] =
          ufcFavorite.favoriteId.toString();
      });

      const actualFavoriteTracks = Object.keys(userFavorites);

      dispatch(setUserFavoriteTracks(actualFavoriteTracks));
      dispatch(setUserFavoriteTracksWithId(userFavorites));
    });

export const getUserData = (dispatch: Dispatch) =>
  typeof window !== "undefined" &&
  LoginService.validateSession().then(
    (response: { success: { data: UserData } }) => {
      if (response.success) {
        dispatch(updateUserInfo(response.success.data.user));
        dispatch(setUserLoggedState(true));
      } else {
        dispatch(setUserLoggedState(false));
      }
    }
  );
