import styled, { css } from "styled-components";
import {
  fontCondensedNormal,
  fontMedium,
  fontNormal,
  fontBold
} from "@tvg/atomic-ui/_static/Typography";
import { buildColor } from "@tvg/design-system";

export const Wrapper = styled.div<{
  hasMinHeight: boolean;
  hasPaddingBottom: boolean;
  isLoading: boolean;
}>`
  position: relative;
  width: 100%;
  background: ${buildColor("blue", "000")};
  ${({ hasMinHeight }) => css`
    min-height: ${hasMinHeight ? "calc(100vh - 48px)" : "775px"};
  `}
  ${({ hasPaddingBottom }) =>
    hasPaddingBottom &&
    css`
      padding-bottom: 12px;
    `}
`;

export const Header = styled.div<{
  hasDesktopGap: boolean;
  isLoading: boolean;
}>`
  position: sticky;
  top: calc(var(--header-height, -8px) + 8px);
  z-index: 2;
  ${({ isLoading }) =>
    isLoading &&
    css`
      display: none;
    `}
  ${({ hasDesktopGap }) =>
    hasDesktopGap &&
    css`
      margin-bottom: 12px;
      &::before {
        content: "";
        position: absolute;
        top: -8px;
        right: 0;
        width: 100%;
        height: 8px;
        box-shadow: inset 0 1px 2px rgba(17, 43, 68, 0.08);
        background-color: ${buildColor("blue", "900")};
      }
    `}

  div {
    font-family: ${fontCondensedNormal};
    color: ${buildColor("grey", "800")};
  }
`;

export const Content = styled.div`
  max-width: 768px;
  margin: 0 auto;
`;

export const ImageAndLocationSection = styled.div`
  width: 100%;
  border-bottom: 1px solid ${buildColor("blue", "100")};
  background-color: ${buildColor("white", "900")};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
`;

export const ImageContainer = styled.div<{
  smallerSize: boolean;
}>`
  width: ${({ smallerSize }) => (smallerSize ? "30%" : "60%")};
  margin-bottom: 12px;
`;

export const UpcomingRacesSection = styled.div<{
  hasBiggerPaddings: boolean;
}>`
  width: 100%;
  border-bottom: 1px solid ${buildColor("blue", "100")};
  background-color: ${buildColor("white", "900")};
  margin-bottom: 12px;
  padding: ${({ hasBiggerPaddings }) =>
    hasBiggerPaddings ? "20px 0 0" : "12px 0 0"};
`;

export const UpcomingRacesTitle = styled.h2<{
  hasBiggerPaddings: boolean;
}>`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 16px;
  font-weight: normal;
  margin: ${({ hasBiggerPaddings }) =>
    hasBiggerPaddings ? "0 20px 20px" : " 0 12px 12px;"};
`;

export const UpcomingRacesChildren = styled.div<{
  hasBiggerPaddings: boolean;
}>`
  ${({ hasBiggerPaddings }) =>
    hasBiggerPaddings &&
    css`
      p {
        margin-left: 8px;
      }

      div[role="button"] {
        margin-left: 8px;
      }
    `};

  div[role="button"] {
    width: fit-content;
    padding-right: 0;
  }
`;

export const TrackDetailsContent = styled.div<{
  hasBiggerPaddings: boolean;
}>`
  width: 100%;
  border-bottom: 1px solid ${buildColor("blue", "100")};
  background-color: ${buildColor("white", "900")};
  padding: ${({ hasBiggerPaddings }) => (hasBiggerPaddings ? "20px" : "12px")};
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;

  > h2 {
    position: relative;
    color: ${buildColor("grey", "900")};
    font-family: ${fontMedium};
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 8px;

    &:not(:first-of-type) {
      margin-top: ${({ hasBiggerPaddings }) =>
        hasBiggerPaddings ? "41px" : "25px"};
      &::before {
        position: absolute;
        content: "";
        top: ${({ hasBiggerPaddings }) =>
          hasBiggerPaddings ? "-20px" : "-12px"};
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${buildColor("blue", "100")};
      }
    }
  }

  > p {
    &:not(:blank),
    :not(:empty) {
      margin-bottom: 8px;
    }
  }

  a {
    display: inline-block;
    margin-top: -2px;
    color: ${buildColor("blue_accent", "500")};
  }
`;

export const FavoriteTrackContainer = styled.button<{
  isDesktop: boolean;
}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 11px 12px;
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          border: 1px solid ${buildColor("blue", "100")};
          box-shadow: 0px 1px 3px rgba(17, 43, 68, 0.12);
          border-radius: 2px;
          right: 8px;
          top: 12px;
          background-color: ${buildColor("white", "900")};

          &:hover {
            background-color: ${buildColor("blue_accent", "000")};
            box-shadow: 0px 2px 4px rgba(17, 43, 68, 0.14);
          }

          &:active {
            background-color: ${buildColor("blue_accent", "100")};
            box-shadow: none;
          }
        `
      : css`
          right: 0;
          top: 2px;
          background-color: transparent;
        `}
`;

export const TitleFavoriteButton = styled.span`
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: 14px;
  margin-left: 8px;
  font-weight: normal;
`;
