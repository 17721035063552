import React, { ReactNode } from "react";
import TrackSplitter from "@tvg/atomic-ui/_atom/TrackSplitter";
import { Container, Title } from "./styled-components";

interface Props {
  title: string;
  isGroupSection?: boolean;
  children: ReactNode;
}

const RaceTracksSection = ({
  title,
  isGroupSection = false,
  children
}: Props) => (
  <Container addMargin={isGroupSection}>
    {isGroupSection ? (
      <Title>{title}</Title>
    ) : (
      <TrackSplitter letter={title} isSEORacetracks />
    )}
    {children}
  </Container>
);

export default RaceTracksSection;
