import { UserInfo } from "./types";

export type SetUserLoggedState = {
  type: "USER_LOGGED_STATE";
  payload: {
    isLogged: boolean;
  };
};
export type UpdateUserInfo = {
  type: "USER_INFO_UPDATE";
  payload: { user: UserInfo };
};
export type ResetUserInfo = {
  type: "USER_INFO_RESET";
  payload: {};
};

export type UserActions = SetUserLoggedState | UpdateUserInfo | ResetUserInfo;

export const setUserLoggedState = (isLogged: boolean): SetUserLoggedState => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const updateUserInfo = (user: UserInfo): UpdateUserInfo => ({
  type: "USER_INFO_UPDATE",
  payload: {
    user
  }
});

export const resetUserInfo = (): ResetUserInfo => ({
  type: "USER_INFO_RESET",
  payload: {}
});
