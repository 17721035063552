import mediator from "@tvg/mediator";
import uwtService from "@tvg/api/uwt";

export const onTrackListPageViewGtm = (
  location: Object,
  residenceState: string,
  accountId: string
) => {
  if (!!accountId && !!residenceState) {
    uwtService
      .getBalance(accountId, false)
      .then((response: { data: { balance: { toFixed: Function } } }) =>
        mediator.base.dispatch({
          type: "RACETRACKS:PAGE_VIEW",
          payload: {
            location,
            residenceState,
            accountId,
            loginStatus: "Logged In",
            balance: String(response.data.balance.toFixed(2))
          }
        })
      )
      .catch(() =>
        mediator.base.dispatch({
          type: "RACETRACKS:PAGE_VIEW",
          payload: {
            location,
            residenceState,
            accountId,
            loginStatus: "Logged In",
            balance: undefined
          }
        })
      );
  } else {
    mediator.base.dispatch({
      type: "RACETRACKS:PAGE_VIEW",
      payload: {
        location,
        residenceState: "",
        accountId: "",
        loginStatus: "Logged Out",
        balance: undefined
      }
    });
  }
};

export const onTrackClickGtm = (trackName: string, destinationUrl: string) => {
  mediator.base.dispatch({
    type: "RACETRACKS:TRACK_CLICK",
    payload: {
      trackName,
      destinationUrl
    }
  });
};

export const onFavoriteClickGtm = (trackName: string) => {
  mediator.base.dispatch({
    type: "RACETRACKS:ADD_FAVORITE_CLICK",
    payload: {
      trackName
    }
  });
};

export const onFavoriteSuccessGtm = (trackName: string) => {
  mediator.base.dispatch({
    type: "RACETRACKS:ADD_FAVORITE_SUCCESS",
    payload: {
      trackName
    }
  });
};

export const onSeeTodaysRacingClickGtm = (destinationUrl: string) => {
  mediator.base.dispatch({
    type: "RACETRACKS:SEE_ALL_TODAYS_RACING",
    payload: {
      destinationUrl
    }
  });
};

export const onReturnArrowClickGtm = () => {
  mediator.base.dispatch({
    type: "RACETRACKS:RETURN_TO_RACETRACKS"
  });
};
