import styled, { css } from "styled-components";
import {
  generateTransition,
  easeInOut
} from "@tvg/atomic-ui/_static/Transitions";
import { buildColor } from "@tvg/design-system";

export const Wrapper = styled.div<{
  hasPaddingBottom: boolean;
  hasMinHeight: boolean;
  isLoading: boolean;
}>`
  position: relative;
  width: 100%;
  background: ${buildColor("blue", "000")};
  ${({ hasMinHeight, isLoading }) => {
    if (hasMinHeight || isLoading) {
      return css`
        min-height: ${hasMinHeight ? "100vh" : "775px"};
      `;
    }
    return null;
  }}
  ${({ hasPaddingBottom }) =>
    hasPaddingBottom &&
    css`
      padding-bottom: 20px;
    `}
`;

export const Header = styled.div<{
  hasDesktopGap: boolean;
  isLoading: boolean;
}>`
  position: sticky;
  top: calc(var(--header-height, -8px) + 8px);
  z-index: 2;
  margin-bottom: 12px;
  ${({ isLoading }) =>
    isLoading &&
    css`
      display: none;
    `}
  ${({ hasDesktopGap }) =>
    hasDesktopGap &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: -8px;
        right: 0;
        width: 100%;
        height: 8px;
        box-shadow: inset 0 1px 2px rgba(17, 43, 68, 0.08);
        background-color: ${buildColor("blue", "900")};
      }
    `}
`;

export const PageHeaderContainer = styled.div<{
  isVisible: boolean;
}>`
  max-height: 98px;
  overflow: hidden;
  transition: ${generateTransition(easeInOut, "max-height")};
  ${({ isVisible }) =>
    !isVisible &&
    css`
      max-height: 0;
    `}
`;

export const SearchHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${buildColor("blue_accent", "000")};
  padding: 12px;
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
`;

// TODO: this input will be removed and moved to Design System after the desktop release

export const CustomSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  border: 1px solid ${buildColor("blue", "000")};
  border-radius: 4px;

  &:focus-within {
    border: 1px solid ${buildColor("blue_accent", "500")};
    box-shadow: 0 1px 3px rgba(17, 43, 68, 0.12);
  }
`;

export const CustomSearchIcon = styled.span`
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 8px;
  border-right: none;
  border-radius: 4px 0 0 4px;

  path {
    stroke: ${buildColor("grey", "500")};
  }
`;

export const CustomSearchInput = styled.input`
  width: 100%;
  height: 32px;
  padding: 8px;
  border-radius: 0 4px 4px 0;
  border-left: none;
  font-size: 14px;
  color: ${buildColor("grey", "900")};

  &::placeholder {
    color: ${buildColor("grey", "600")};
  }

  &:focus {
    outline: none;
  }
`;

export const CancelSearchContainer = styled.div<{
  isVisible: boolean;
}>`
  transition: ${generateTransition(easeInOut, "opacity")};
  ${({ isVisible }) =>
    !isVisible
      ? css`
          width: 0;
          height: 0;
          overflow: hidden;
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
`;

export const CustomClearButton = styled.button<{
  isVisible: boolean;
}>`
  background-color: transparent;
  padding: 0 8px;
  cursor: pointer;
  ${({ isVisible }) => !isVisible && "display: none;"}
`;

export const SearchInputContainer = styled.div`
  position: absolute;
  width: 280px;
  top: 30px;
  right: 12px;
`;

export const Content = styled.div`
  max-width: 768px;
  margin: 0 auto;
`;
