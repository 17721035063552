/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import type { Dispatch, Action } from "redux";
import { get } from "lodash";
import ApolloContext from "@tvg/utils/apolloContext";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import buildRaceUrl from "@tvg/formatter/url";
import withRouter from "@tvg/utils/withCustomRouter";
import TrackInfoComponent from "@tvg/tracks/src/v2/components/track-individual-info";
import { Props } from "./types";
import { State as Store } from "../../../store/reducers";
import { setSeoTrackList, setFromQuickLinks } from "../../../store/actions/app";

const RaceTrackDetails = (props: Props) => {
  const firstRender = useRef(true);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const searchRaceNumber = props.location.search.match(/race=([^&#]*)/);
  const shouldRenderTrackInfo = !get(searchRaceNumber, "[1]", null);

  useEffect(() => {
    const { trackAbbr, trackName } = props.match.params as {
      trackAbbr: string;
      trackName: string;
    };
    const nextRaceFromTrack = getNextRaceFromTrack(trackAbbr);

    if (
      (!searchRaceNumber || !trackAbbr) &&
      props.races.length &&
      (!props.enableSeoRaceTracks ||
        (props.enableSeoRaceTracks &&
          props.fromQuickLinks &&
          nextRaceFromTrack))
    ) {
      redirectToNextRace(
        trackAbbr,
        trackName,
        get(searchRaceNumber, "[1]", null)
      );
    }
    return () => {
      props.dispatch(setFromQuickLinks(false));
    };
  }, []);

  useEffect(() => {
    if (!props.isLogged && !firstRender.current) {
      navigate("/");

      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: "/" }
      });
    }
    firstRender.current = false;
  }, [props.isLogged]);

  const getNextRaceFromTrack = (trackAbbr: string) => {
    const nextRace = props.races.filter(
      (r) => r.status && (r.status.code === "O" || r.status.code === "IC")
    )[0];
    const nextRaceForTrack = props.races.find((r) => r.trackCode === trackAbbr);
    return nextRace && nextRaceForTrack;
  };

  const redirectToNextRace = (
    trackAbbr: string,
    trackName: string,
    raceNumber: string | null
  ) => {
    let nextRaceUrl = "";

    if (trackAbbr && !raceNumber) {
      const nextRaceForTrack = props.races.find(
        (r) => r.trackCode === trackAbbr
      );

      if (nextRaceForTrack) {
        nextRaceUrl = buildRaceUrl(
          nextRaceForTrack.trackCode,
          nextRaceForTrack.trackName,
          nextRaceForTrack.number
        );
      } else {
        nextRaceUrl = buildRaceUrl(trackAbbr, trackName, 1);
      }
    }

    if (nextRaceUrl) {
      setLoading(true);

      // setTimeout need for TVG4 navigation delay
      setTimeout(() => {
        props.history.push(nextRaceUrl, {
          ...props.history.location.state,
          fromQuickLinks: props.fromQuickLinks
        });

        mediator.base.dispatch({
          type: "TVG4_NAVIGATION",
          payload: { route: nextRaceUrl }
        });

        setLoading(false);
      }, 0);
    }
  };

  return shouldRenderTrackInfo && props.enableSeoRaceTracks ? (
    <ApolloContext.Consumer>
      {(value: any) => (
        <TrackInfoComponent
          // @ts-ignore
          device={tvgConf().device}
          fcpClient={value.fcpClient}
          rdaClient={value.rdaClient}
          content={props.content}
          // @ts-ignore
          tracksData={props.tracks}
          wagerProfile={props.wagerProfile}
          fromQuickLinks={props.fromQuickLinks}
          isLoading={isLoading}
          isLoadingContent={false}
          shouldUpdate={false}
          setTracksData={setSeoTrackList}
        />
      )}
    </ApolloContext.Consumer>
  ) : null;
};

RaceTrackDetails.defaultProps = {
  wagerProfile: "PORT-Generic",
  content: "",
  races: [],
  tracks: []
};

export const mapStateToProps = (store: Store, ownProps: Props) => {
  const { trackAbbr } = ownProps.match.params as {
    trackAbbr: string;
  };
  const defaultMessage = get(store, "capi.seoMessages.raceTrackHubDEF", "");

  return {
    content: get(
      store,
      `capi.seoMessages.raceTrackHub${trackAbbr}`,
      defaultMessage
    ),
    wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
    races: get(store, "mtpStatus.mtpStatus", []),
    tracks: get(store, "app.seoTrackList", []),
    fromQuickLinks: get(store, "app.fromQuickLinks", false),
    enableSeoRaceTracks: get(
      store,
      "capi.featureToggles.enableSeoRaceTracks",
      false
    )
  };
};

export default withRouter(
  connect(mapStateToProps, (dispatch: Dispatch<Action>) => ({
    dispatch
  }))(RaceTrackDetails)
);
