import { BinaryFn } from "@tvg/ts-types/Functional";

export interface BannerProps {
  raceStartDate?: Date | null;
  eventStatus: DerbyEventStatus;
  bannerCfg?: HeaderBanner;
  orientation?: "portrait" | "landscape";
  onCtaClick: BinaryFn<string, string, void>;
  qaLabel?: string;
}

export type Variables = Record<
  string,
  string | number | Date | undefined | null
>;

export interface RichTextParserProps {
  content: Array<RichText> | undefined;
  variables?: Variables;
}

export interface CountdownProps {
  // End date in Date format
  endDate?: Date | null;
  // Event status
  eventStatus: DerbyEventStatus;
  // Full date
  forceFullDate?: boolean;
}

export enum DerbyEventStatus {
  PRE = "preEvent",
  DERBY_DAY = "eventDay",
  POST = "default"
}

export interface HomepageEventBannerText {
  label: string;
  size: number;
}

export interface ContentParagraph {
  type: "paragraph";
  content?: Array<ContentText>;
}

export interface ContentHeading {
  type: "heading";
  attrs: {
    level: number;
  };
  content: Array<ContentText>;
}

export interface ContentText {
  type: "text";
  text: string;
  marks?: Array<TextMarks>;
}

export interface TextMarks {
  type: "textStyle";
  attrs: {
    color: string;
  };
}

export type RichText = ContentParagraph | ContentHeading;

export enum BannerConfigType {
  DEFAULT = "default",
  EVENT_DAY = "eventDay",
  PRE_EVENT = "preEvent",
  FRIDAY_PRE_EVENT = "fridayPreEvent",
  THURSDAY_PRE_EVENT = "thursdayPreEvent"
}

export enum BannerContext {
  DESKTOP = "desk",
  MOBILE_SA = "mobile",
  X_SELL = "xSell",
  HOME_PAGE = "homePage",
  PROGRAM_PAGE = "programPage"
}

export interface CtaButton {
  button_type: string;
  label: string;
  link_url: {
    url: string;
  };
}

export interface HeaderBanner {
  context: Array<BannerContext>;
  background_image: {
    filename: string;
    alt: string;
  };
  cta_buttons_bg_color?: string;
  cta_buttons: Array<CtaButton>;
  dynamic_text_bg_color?: string;
  dynamic_text?: {
    type: "doc";
    content: Array<RichText>;
  };
  background_image_link?: {
    url?: string;
  };
}

export type HomepageHeaderBanner = Partial<
  Record<BannerConfigType, Array<HeaderBanner>>
>;
