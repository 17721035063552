/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import ApolloContext from "@tvg/utils/apolloContext";
import TracksListComponent from "@tvg/tracks/src/v2/components/tracks-list";
import tvgConf from "@tvg/conf";
import { setSeoTrackList } from "../../../store/actions/app";
import { State as Store } from "../../../store/reducers";
import { Props } from "./types";

const RaceTracksList = (props: Props) =>
  props.enableSeoRaceTracks ? (
    <ApolloContext.Consumer>
      {(value: any) => (
        <TracksListComponent
          {...props}
          // @ts-ignore
          device={tvgConf().device}
          fcpClient={value.fcpClient}
          setTracksData={setSeoTrackList}
        />
      )}
    </ApolloContext.Consumer>
  ) : null;

export const mapStateToProps = (store: Store) => ({
  enableSeoRaceTracks: get(
    store,
    "capi.featureToggles.enableSeoRaceTracks",
    false
  )
});

export default connect(mapStateToProps)(RaceTracksList);
