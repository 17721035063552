import { useEffect, useState } from "react";
import { gql, useQuery, useSubscription } from "@apollo/client";
import tvgConf from "@tvg/conf";
import { Race } from "@tvg/ts-types/Race";
import { useWagerProfile } from "@tvg/custom-hooks";
import { useDispatch, useSelector } from "react-redux";
import mediator from "@tvg/mediator";
import { getHasRequestedLogin } from "@tvg/pp/src/store/selectors";
import { ApplicationState } from "@tvg/tracks/src/v2/redux/types";
import { get, isArray } from "lodash";
import { MtpRacesUpdate, MtpRaceDate } from "../actions";

export const getDateRace = (store: ApplicationState) =>
  get(store, "mtpStatus.raceDate");

export const MTP_GLOBAL_UPDATE = gql`
  subscription raceMtpUpdate(
    $wagerProfile: String!
    $product: String
    $device: String
    $brand: String
  ) {
    featuredTrackAndRaceMtpOrStatusUpdate(profile: $wagerProfile) {
      id
      tvgRaceId
      mtp
      postTime
      track {
        id
        code
        featured
        name
      }
      trackCode
      trackName
      highlighted(product: $product, device: $device, brand: $brand) {
        description
        pinnedOrder
        action
        style
      }
      promos(product: $product, brand: $brand) {
        rootParentPromoID
        isAboveTheLine
        promoPath
        isPromoTagShown
      }
      type {
        code
      }
      status {
        code
      }
      video {
        liveStreaming
        streams
        onTvg
        onTvg2
        hasReplay
      }
    }
  }
`;

const useMtpUpdate = (shouldRunSubscription: boolean) => {
  const wagerProfile: string = useWagerProfile();
  const hasRequestedLogin = useSelector(getHasRequestedLogin);
  const [shouldSkip, setShouldSkip] = useState(true);
  const raceDate = useSelector(getDateRace);
  const dispatch = useDispatch();

  const { data } = useQuery(
    gql`
      query getRaceDate {
        raceDate
      }
    `,
    {
      pollInterval: 300000,
      fetchPolicy: "network-only",
      ssr: false
    }
  );

  useEffect(() => {
    if (wagerProfile && hasRequestedLogin && shouldRunSubscription) {
      setShouldSkip(false);
    }
  }, [wagerProfile, hasRequestedLogin]);

  useEffect(() => {
    if (data?.raceDate && raceDate !== data.raceDate) {
      dispatch(MtpRaceDate(data.raceDate));
    }
  }, [data?.raceDate]);

  useSubscription(MTP_GLOBAL_UPDATE, {
    variables: {
      wagerProfile,
      ...tvgConf().graphContext()
    },
    skip: shouldSkip,
    onData: (result) => {
      const updatedData =
        result?.data?.data?.featuredTrackAndRaceMtpOrStatusUpdate;

      if (updatedData) {
        dispatch(MtpRacesUpdate(updatedData || []));
      }

      mediator.base.dispatch({
        type: "URP:MTP_UPDATE",
        payload: {
          data: updatedData
        }
      });
      if (
        ((isArray(updatedData) && updatedData) || []).some(
          (raceData: Race) => raceData.status.code === "RO"
        )
      ) {
        mediator.base.dispatch({
          type: "URP:MTP_UPDATE_CLOSED_RACES",
          payload: {}
        });
      }
    }
  });
};

export default useMtpUpdate;
