// @flow
import type { NullaryFn, UnaryFn, BinaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";
import type { WagerProfile } from "@tvg/types/User";
import type { EmptyMessages } from "@tvg/atomic-ui/_molecule/EmptyStateMessages";
import type { Dispatch } from "redux";

export type TracksTypes =
  | "TOP_TRACKS"
  | "TRACKS_AZ"
  | "RESULTED_TRACKS_AZ"
  | "FAVORITE_TRACKS"
  | "RESULTED_FAVORITE_TRACKS";

export type Track = {
  id: string,
  isFavorite: boolean,
  favorite: {
    id: ?number
  },
  isOpen?: boolean,
  featured: boolean,
  isFinished: boolean,
  name: string,
  shortName: string,
  code: string,
  numberOfRaces: number,
  isGreyhound: boolean,
  hasAboveTheLinePromo: boolean,
  hasResultedState: boolean,
  hasPromo: boolean,
  userPromotions?: { optedIn: boolean }[],
  isPromoTagShown: boolean
};

export type TrackComponentProps = {
  device: Device,
  wagerProfile: WagerProfile,
  amountOfFavoriteTracks: number,
  isLogged: boolean,
  accountId: string,
  isLoading: boolean,
  shouldUpdate: boolean,
  storedFavoriteTracks: string[],
  title: string,
  openTracks: string[],
  featuredTracks: Track[],
  tracks: Track[],
  fetchFinishedTracks: boolean,
  setFavoriteTrack: BinaryFn<string, ?number, void>,
  removeFavoriteTrack: UnaryFn<string, void>,
  toggleOpenTrackRow: UnaryFn<string[], void>,
  rdaClient: mixed,
  trackType: TracksTypes,
  raceTypeFilters: string[],
  regionFilters: string[],
  hasError: boolean,
  signalLoadedComponent: UnaryFn<string, void>,
  clearRaceFilters: NullaryFn<void>,
  clearRegionFilters: NullaryFn<void>,
  numberOfFavorites: number,
  hasActiveFilters: boolean,
  onClearFilters: NullaryFn<void>,
  refetch: NullaryFn<void>,
  dispatch: Dispatch<*>,
  emptyMessages: EmptyMessages,
  useIsPromoTagShownFlag: boolean,
  showHeader?: boolean,
  isAccountCompliant: boolean
};

export type TrackComponentState = {
  firstRender: boolean,
  innerUpdate: boolean,
  forceRefetch: boolean,
  favorites: {
    [string]: number
  }
};

type SkipFunction = UnaryFn<*, boolean>;

export type TrackComponentBehavior = {
  template:
    | Class<React$Component<any, any>>
    | React.StatelessFunctionalComponent,
  query: mixed,
  rdaQuery: mixed,
  graphOptions: any,
  skipQuery: SkipFunction,
  skipRDAQuery: SkipFunction,
  usesRDA: boolean,
  onlyUsesRDA: boolean,
  onlyFetchResultedTracks: boolean,
  shouldComponentUpdate: BinaryFn<*, *, *>,
  renderTracksLetters: boolean,
  getDerivedStateFromProps: BinaryFn<*, *, *>
};

export default {
  TOP_TRACKS: "TOP_TRACKS",
  TRACKS_AZ: "TRACKS_AZ",
  RESULTED_TRACKS_AZ: "RESULTED_TRACKS_AZ",
  FAVORITE_TRACKS: "FAVORITE_TRACKS",
  RESULTED_FAVORITE_TRACKS: "RESULTED_FAVORITE_TRACKS"
};
