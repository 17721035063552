import { gql } from "@apollo/client";

export const TRACK_RACES = gql`
  fragment TrackRaces on Track {
    id
    races {
      id
      tvgRaceId
      number
      postTime
      mtp
      userPromotions {
        id
        isAboveTheLine
        optedIn
        isPromoTagShown
      }
      status {
        code
      }
    }
  }

  query getTrackRacesRDA(
    $accountId: Int!
    $wagerProfile: String!
    $sortByRaceNumber: RaceListSort
    $raceFilters: RaceListFilter
    $trackFilters: TrackListFilter
    $product: String
    $brand: String
  ) {
    tracks(
      accountId: $accountId
      profile: $wagerProfile
      trackFilter: $trackFilters
      raceFilter: $raceFilters
      raceSort: $sortByRaceNumber
      product: $product
      brand: $brand
    ) {
      isGreyhound
      ...TrackRaces
    }
  }
`;

export default TRACK_RACES;
