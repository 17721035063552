import { TrackData } from "./types";

export type SetSeoTrackList = {
  type: "SET_SEO_TRACK_LIST";
  payload: Array<TrackData>;
};

export type SetFromQuickLinks = {
  type: "SET_FROM_QUICK_LINKS";
  payload: boolean;
};

export type SetTodaysTracks = {
  type: "SET_TODAYS_TRACKS";
  payload: Array<string>;
};

export type AppActions = SetSeoTrackList | SetFromQuickLinks | SetTodaysTracks;

export const setSeoTrackList = (
  seoTrackList: Array<TrackData>
): SetSeoTrackList => ({
  type: "SET_SEO_TRACK_LIST",
  payload: seoTrackList
});

export const setFromQuickLinks = (
  fromQuickLinks: boolean
): SetFromQuickLinks => ({
  type: "SET_FROM_QUICK_LINKS",
  payload: fromQuickLinks
});

export const setTodaysTracks = (
  todaysTracks: Array<string>
): SetTodaysTracks => ({
  type: "SET_TODAYS_TRACKS",
  payload: todaysTracks
});
