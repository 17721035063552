import type { RaceStatusEnum } from "@tvg/ts-types/Race";

export type MtpStatus = {
  id: string;
  mtp: number;
  tvgRaceId: number;
  postTime: string;
  trackCode: string;
  trackName: string;
  number: string;
  status: {
    code: RaceStatusEnum;
  };
};

type MtpUpdateAction = {
  type: "MTP_STATUS_UPDATE";
  payload: { mtpStatus: MtpStatus[]; raceDate: string };
};

type MtpRacesUpdateAction = {
  type: "UPDATE_MTP_RACES";
  payload: { races: MtpStatus[] };
};

type MtpRaceDateAction = {
  type: "UPDATE_MTP_RACEDATE";
  payload: { raceDate: string };
};

export type Actions =
  | MtpUpdateAction
  | MtpRacesUpdateAction
  | MtpRaceDateAction;

export const MtpUpdate = (
  mtpUpdate: MtpStatus[],
  raceDate: string
): MtpUpdateAction => ({
  type: "MTP_STATUS_UPDATE",
  payload: { mtpStatus: mtpUpdate, raceDate }
});

export const MtpRacesUpdate = (races: MtpStatus[]): MtpRacesUpdateAction => ({
  type: "UPDATE_MTP_RACES",
  payload: { races }
});

export const MtpRaceDate = (raceDate: string): MtpRaceDateAction => ({
  type: "UPDATE_MTP_RACEDATE",
  payload: { raceDate }
});

export default MtpUpdate;
