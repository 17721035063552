// @flow
import React, { Component } from "react";
import type { RaceTemplate, Promo } from "@tvg/types/Race";
import type { Device } from "@tvg/types/Device";
import type {
  BinaryFn,
  SixFn,
  UnaryFn,
  NullaryFn
} from "@tvg/types/Functional";
import type { UserOptedInPromos } from "@tvg/types/User";
import { noop, get } from "lodash";
import EmptyRaceList from "@tvg/atomic-ui/_molecule/EmptyRaceList";
import RacesMask from "../../_static/Masks/races";
import RaceLinkAbbr from "../../_molecule/RaceLinkAbbr";
import TrackLine from "../../_organism/TrackLine";
import RacesContainer from "./styled-components";
import MessageBox from "../../_molecule/MessageBox";
import ScrollWrapper from "../../_molecule/ScrollWrapper";

type Props = {
  /**
   * List of races
   */
  raceList: RaceTemplate[],
  /**
   * Track code
   */
  trackCode: string,
  /**
   * Track name
   */
  trackName: string,
  /**
   * Loading state
   */
  isLoading: boolean,
  /**
   * Aims to control when the auto scroll should happen
   */
  isDisplayingRaces: boolean,
  /**
   * Number of the race to scroll into
   */
  raceIndexScroll?: number,
  /**
   * Prefix to identify the section of the race to scroll into
   */
  idPrefix: string,
  /**
   * Tells if it is a greyhound track
   */
  isGreyhound: boolean,
  /**
   * Callback to be called when clicking on a race
   */
  onRaceClick: BinaryFn<RaceTemplate, string, void>,
  /**
   * Enables the talent pick tool context
   */
  isTalentPicksContext: boolean,
  /**
   * Enables the promo badge on race link
   */
  hasPromoBadge: boolean,
  /**
   * Object with the user opted-in promos
   */
  optedInPromos: UserOptedInPromos,
  /**
   * Has new layout for scrollable races and promos
   */
  hasNewLayout: boolean,
  /**
   * Device type
   */
  device: Device,
  /**
   * Send gtm on scroll
   */
  sendGTMScrollEvent: UnaryFn<boolean, void>,
  /**
   * On race select with new layout
   */
  onRaceSelect: SixFn<
    string,
    string,
    RaceTemplate,
    string,
    boolean,
    boolean,
    void
  >,
  /**
   * Is favorite track
   */
  isFavorite: boolean,
  isOpen: boolean,
  isSEORacetracks: boolean,
  onEmptyActionClick: NullaryFn<*>,
  isLogged: boolean,
  isMTPNewRules: boolean,
  useIsPromoTagShownFlag: boolean
};

const buildRaceContainerId = (idPrefix: string, trackCode: string) =>
  `${idPrefix}_racesContainer-${trackCode}`;

export default class RaceContainer extends Component<Props> {
  static defaultProps = {
    idPrefix: "",
    raceList: [],
    trackCode: "",
    trackName: "",
    raceIndexScroll: 0,
    onRaceClick: noop,
    isGreyhound: false,
    isDisplayingRaces: false,
    isLoading: true,
    isTalentPicksContext: false,
    hasPromoBadge: false,
    optedInPromos: {},
    hasNewLayout: false,
    isDesktop: false,
    onRaceSelect: noop,
    isFavorite: false,
    sendGTMScrollEvent: noop,
    device: "mobile",
    isOpen: false,
    isSEORacetracks: false,
    onEmptyActionClick: noop,
    isLogged: false,
    isMTPNewRules: false,
    useIsPromoTagShownFlag: false
  };

  didScrollOnce: boolean = false;

  componentDidUpdate() {
    if (this.props.idPrefix && this.props.raceIndexScroll) {
      const elementToScrollId = `${this.props.idPrefix}_raceElement_${this.props.trackCode}_${this.props.raceIndexScroll}`;
      const parentElement = document.getElementById(
        buildRaceContainerId(this.props.idPrefix, this.props.trackCode)
      );
      const elementToScroll = document.querySelector(`#${elementToScrollId}`);
      const elementToScrollPosition = elementToScroll
        ? elementToScroll.offsetLeft
        : 0;

      if (!this.props.isOpen) {
        this.didScrollOnce = false;
      }

      if (
        elementToScroll &&
        parentElement &&
        // $FlowFixMe
        typeof parentElement.scrollTo === "function"
      ) {
        if (!this.didScrollOnce) {
          const offsetToScroll =
            get(this.props, "raceIndexScroll", 0) === 1 ? 12 : 8;

          const to = elementToScrollPosition - offsetToScroll;

          // This is a trick to make a scroll smooth on Safari, because
          // smooth scroll feature on Safari is not implemented yet
          const smoothScrollFeature =
            // $FlowFixMe
            "scrollBehavior" in document.documentElement.style;
          if (!smoothScrollFeature) {
            let i = 0;
            const int = setInterval(() => {
              if (i > to - 20) i += 1;
              else if (i > to - 40) i += 3;
              else if (i > to - 80) i += 8;
              else if (i > to - 160) i += 18;
              else if (i > to - 200) i += 24;
              else if (i > to - 300) i += 40;
              else i += 60;
              // $FlowFixMe
              parentElement.scrollTo(i, 0);
              if (i >= to) clearInterval(int);
            }, 15);
          } else {
            // $FlowFixMe
            parentElement.scrollTo({
              left: to,
              behavior: "smooth"
            });
          }

          this.didScrollOnce = true;
        }
        // The next two lines are part of a shameful hack to activate an element reflow
        // this fixes the "stuck scroll" bug described in MEP-947
        if (this.props.device !== "desktop") {
          parentElement.style.overflowX = "hidden";
          setTimeout(() => {
            parentElement.style.overflowX = "auto";
          }, 0);
        }
      }
    }
  }

  renderOldLayout() {
    let outputMarkup;
    const {
      isLoading,
      raceList,
      optedInPromos,
      idPrefix,
      hasPromoBadge,
      onRaceClick,
      isTalentPicksContext,
      trackCode,
      isMTPNewRules
    } = this.props;

    if (isLoading) {
      outputMarkup = <RacesMask />;
    } else if (!raceList.length) {
      outputMarkup = (
        <MessageBox
          className="emptyStateRacesContainer"
          type="info"
          message="There are no races running for this track. Please check back later."
          title=""
          hasIcon
          isVertical={false}
        />
      );
    } else {
      const getRacePromo = (promo: Promo): Promo => {
        if (promo) {
          return {
            ...promo,
            isOptedIn: get(optedInPromos, promo.rootParentPromoID)
          };
        }
        return promo;
      };

      outputMarkup = raceList.map((race: RaceTemplate): React$Element<*> => (
        <RaceLinkAbbr
          idPrefix={idPrefix}
          key={`key:${race.trackCode}${race.number}`}
          trackName={race.trackName}
          trackCode={race.trackCode}
          number={race.number}
          raceStatus={race.raceStatus}
          hasBets={race.hasBets}
          promo={hasPromoBadge ? getRacePromo(race.promo) : null}
          hasResults={race.hasResults}
          postTime={race.postTime}
          mtp={race.mtp}
          showTrackCode={get(race, "showTrackCode", false)}
          onRaceClick={(url: string) => onRaceClick(race, url)}
          isTalentPicksContext={isTalentPicksContext}
          isMTPNewRules={isMTPNewRules}
        />
      ));
    }

    return (
      <ScrollWrapper centerFaders={false}>
        <RacesContainer
          id={buildRaceContainerId(idPrefix, trackCode)}
          key={isLoading ? "loading-mask" : "races"}
          hasNewLayout
        >
          {outputMarkup}
        </RacesContainer>
      </ScrollWrapper>
    );
  }

  renderNewLayout() {
    const {
      isLoading,
      raceList,
      idPrefix,
      trackCode,
      isFavorite,
      sendGTMScrollEvent,
      onRaceSelect,
      isSEORacetracks
    } = this.props;

    if (
      ((isLoading && !this.props.isDisplayingRaces) ||
        (isLoading && !raceList.length)) &&
      this.props.isOpen
    ) {
      this.didScrollOnce = false;
      return (
        <div style={{ width: "100%", height: "72px", overflow: "hidden" }}>
          <RacesMask />
        </div>
      );
    }
    if (!raceList.length && this.props.isOpen) {
      return isSEORacetracks ? (
        <EmptyRaceList
          title="No races available today"
          actionText="See all today's racing"
          onActionClick={this.props.onEmptyActionClick}
        />
      ) : (
        <MessageBox
          className="emptyStateRacesContainer"
          type="info"
          message="There are no races running for this track. Please check back later."
          title=""
          hasIcon
          isVertical={false}
        />
      );
    }
    return (
      <TrackLine
        key={`trackLine-${trackCode}`}
        idPrefix={idPrefix}
        device={this.props.device}
        isFavorite={isFavorite}
        isGreyhound={this.props.isGreyhound}
        trackCode={trackCode}
        trackName={get(this.props, "trackName", "")}
        trackRaces={raceList}
        qaLabel="trackLine"
        sendGTMScrollEvent={sendGTMScrollEvent}
        onRaceSelect={onRaceSelect}
        isLogged={this.props.isLogged}
        isMTPNewRules={this.props.isMTPNewRules}
        isSEORacetracks={this.props.isSEORacetracks}
        useIsPromoTagShownFlag={this.props.useIsPromoTagShownFlag}
      />
    );
  }

  render() {
    const { hasNewLayout } = this.props;
    return hasNewLayout ? this.renderNewLayout() : this.renderOldLayout();
  }
}
