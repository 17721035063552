import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import MtpStatus from "@tvg/mtp-update/src";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { History } from "@tvg/ts-types/History";
import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";
import mediator from "@tvg/mediator";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import { getStoreFeatures, getTodaysTracks } from "../store/selectors";
import { Feature } from "../store/actions/types";
import {
  updateTVG4Router,
  updateRouter,
  updateUserFavoriteTracks,
  fetchUserData,
  updateFavoriteTrack
} from "./utils/callbacks";
import {
  getCapiMessages,
  getFavoriteTracks,
  getFeatures,
  getSeoCapiMessages,
  getUserData,
  setFeatureToggles
} from "./utils/helpers";
import RaceTracksList from "./components/RaceTracksList";
import RaceTrackDetails from "./components/RaceTrackDetails";

type Props = {
  history: History;
};

const Main = ({ history }: Props) => {
  const features: Feature[] = useSelector(getStoreFeatures);
  const isLogged: boolean = useSelector(getIsLogged);
  const accountNumber: string = useSelector(getAccountNumber);
  const todaysTracks: string[] = useSelector(getTodaysTracks);

  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== "undefined") {
      mediatorClassic.subscribe(
        "UPDATE_ROUTER",
        updateRouter(history, todaysTracks)
      );
      mediator.base.subscribe("TVG4_NAVIGATION", updateTVG4Router(dispatch));
      mediator.base.subscribe(
        "UPDATE_SESSION_FAVORITE_TRACKS",
        updateFavoriteTrack(dispatch)
      );
      mediatorClassic.subscribe(
        "NEW_FAVORITE_TRACKS",
        updateUserFavoriteTracks(dispatch)
      );
      mediatorClassic.subscribeWithPast(
        "TVG_LOGIN:USER_SESSION_UPDATE",
        fetchUserData(dispatch)
      );

      if (!isEmpty(features)) {
        setFeatureToggles(features, dispatch);
      } else {
        getFeatures(dispatch);
      }

      getCapiMessages(dispatch);
      getSeoCapiMessages(dispatch);
    }

    if (!isLogged) {
      getUserData(dispatch);
    }

    return () => {
      if (typeof window !== "undefined") {
        mediatorClassic.unsubscribe("UPDATE_ROUTER", updateRouter);
        mediator.base.unsubscribe("TVG4_NAVIGATION", updateTVG4Router);
        mediator.base.unsubscribe(
          "UPDATE_SESSION_FAVORITE_TRACKS",
          updateFavoriteTrack
        );
        mediatorClassic.unsubscribe(
          "NEW_FAVORITE_TRACKS",
          updateUserFavoriteTracks
        );
        mediatorClassic.unsubscribe(
          "TVG_LOGIN:USER_SESSION_UPDATE",
          fetchUserData
        );
      }
    };
  }, []);

  useEffect(() => {
    if (isLogged && accountNumber) {
      getFavoriteTracks(accountNumber, dispatch);
    }
  }, [isLogged, accountNumber]);

  return (
    <main>
      <TvgSafeAreaProvider>
        <MtpStatus>
          <Routes>
            {/* @ts-ignore */}
            <Route path="/racetracks/" element={<RaceTracksList />} />
            <Route
              path="/racetracks/:trackAbbr/:trackName/"
              // @ts-ignore
              element={<RaceTrackDetails isLogged={isLogged} />}
            />
          </Routes>
        </MtpStatus>
      </TvgSafeAreaProvider>
    </main>
  );
};

export default withRouter(Main);
