import { Actions } from "./actions";

export interface State {
  fetchedWagerProfile: string;
}

export const initialState: State = {
  fetchedWagerProfile: "PORT-Generic"
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "SET_RACETRACKS_FETCHED_WAGER_PROFILE":
      return {
        ...state,
        fetchedWagerProfile: action.payload.fetchedWagerProfile
      };
    default: {
      return state;
    }
  }
}
