import { gql } from "@apollo/client";

export const GET_TRACK_RACES = gql`
  query getTrackRaces(
    $wagerProfile: String
    $sortByRaceNumber: RaceListSort
    $raceFilters: RaceListFilter
    $product: String
    $brand: String
  ) {
    races: races(
      profile: $wagerProfile
      filter: $raceFilters
      sort: $sortByRaceNumber
    ) {
      id
      tvgRaceId
      number
      postTime
      mtp
      status {
        code
      }
      promos(product: $product, brand: $brand) {
        rootParentPromoID
        isAboveTheLine
        promoPath
        isPromoTagShown
      }
    }
  }
`;

export default GET_TRACK_RACES;
