import { combineReducers } from "redux";

import seoRaceTracksReducer, {
  State as seoRaceTracksState,
  initialState as initialSeoRaceTracksState
} from "@tvg/tracks/src/v2/redux/reducers";

import mtpStatusReducer, {
  initialState as initialMtpStatusState
} from "@tvg/mtp-update/src/reducers";

import { AppState, CapiState, UserState, UserFavorites } from "./types";

import appReducer, { initialState as initialAppState } from "./App/appReducer";

import capiReducer, {
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  initialState as initialUserState
} from "./App/userDataReducer";

import userFavoritesReducer, {
  initialState as initialUserFavorites
} from "./App/userFavoritesReducer";

export type State = {
  app: AppState;
  capi: CapiState;
  userData: UserState;
  userFavorites: UserFavorites;
  seoRaceTracks: seoRaceTracksState;
};

export type RootState = ReturnType<typeof rootReducer>;

export const initialState = {
  app: initialAppState,
  capi: initialCapiState,
  userData: initialUserState,
  userFavorites: initialUserFavorites,
  seoRaceTracks: initialSeoRaceTracksState,
  mtpStatus: initialMtpStatusState
} as State;

const rootReducer = combineReducers({
  app: appReducer,
  capi: capiReducer,
  userData: userDataReducer,
  userFavorites: userFavoritesReducer,
  seoRaceTracks: seoRaceTracksReducer,
  mtpStatus: mtpStatusReducer
});

export default rootReducer;
