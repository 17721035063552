import { useState, useEffect, useCallback } from "react";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import ufcClient from "@tvg/api/ufc";
import mediator from "@tvg/mediator";
import { Device } from "@tvg/ts-types/Device";
import {
  setFavoriteTrack,
  removeFavoriteTrack
} from "@tvg/shared-actions/UserActions";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import { onFavoriteClickGtm, onFavoriteSuccessGtm } from "../utils/gtm";
import { TrackData, FavoriteTracksSuccessResponse } from "../types";

export const updateSessionFavoriteTracks = (
  isAddingFavorite: boolean,
  trackCode: string,
  favoriteId: number
) => {
  mediatorClassic.dispatch("UPDATE_FAVORITE_TRACKS", {});

  mediator.base.dispatch({
    type: "UPDATE_SESSION_FAVORITE_TRACKS",
    payload: {
      isAddingFavorite,
      trackCode,
      favoriteId
    }
  });
};

const useFavorite = (
  favoriteId: number | null,
  trackInfo: TrackData,
  isLogged: boolean,
  accountNumber: string,
  device: Device,
  isAccountCompliant?: boolean
) => {
  const dispatch = useDispatch();

  const [currentId, setCurrentId] = useState(favoriteId);
  const [isLoading, setLoading] = useState(false);
  const [isFavorite, setFavorite] = useState(
    favoriteId !== null && favoriteId > 0
  );

  useEffect(() => {
    if (favoriteId) {
      setCurrentId(favoriteId);
      setFavorite(favoriteId !== null && favoriteId > 0);
    }
  }, [favoriteId]);

  const addOrRemoveFavorites = () => {
    if (!isFavorite || !currentId) {
      addTrackToFavorite(accountNumber);
    } else {
      setLoading(true);
      ufcClient.removeFavorite(accountNumber, currentId).then(() => {
        if (device === Device.DESKTOP) {
          updateSessionFavoriteTracks(false, trackInfo.code, currentId);
        } else {
          dispatch(removeFavoriteTrack(trackInfo.code));
        }
        setCurrentId(null);
        setLoading(false);
      });
    }
    setFavorite((prevState) => !prevState);
  };

  const openLoginWall = () => {
    mediator.base.dispatch({
      type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
      payload: {
        callback: (error: Error, success: Object) => {
          if (!error && success) {
            const localAccountNumber = get(
              success,
              "data.userDetails.accountNumber",
              null
            );
            if (typeof localAccountNumber === "string") {
              setFavorite(true);
              addTrackToFavorite(localAccountNumber);
            }
          }
        },
        triggerAction: "tracks_favorite_change"
      }
    });
  };

  const addTrackToFavorite = (accountId: string) => {
    setLoading(true);
    ufcClient
      .addFavorite(accountId, "track", [trackInfo.code])
      .then((data: FavoriteTracksSuccessResponse) => {
        const updatedId = get(data, "data[0].favoriteId", null);
        if (device === Device.DESKTOP) {
          updateSessionFavoriteTracks(true, trackInfo.code, updatedId);
        } else {
          dispatch(setFavoriteTrack(trackInfo.code, updatedId));
        }
        setCurrentId(updatedId);
        setLoading(false);
        onFavoriteSuccessGtm(trackInfo.name);
      });
  };

  const toggleFavoriteAction = useCallback(() => {
    if (!isLoading) {
      onFavoriteClickGtm(trackInfo.name);
      if (isLogged) {
        if (isAccountCompliant) {
          addOrRemoveFavorites();
        } else {
          onOpenComplianceModal();
        }
      } else {
        openLoginWall();
      }
    }
  }, [
    isLogged,
    accountNumber,
    isFavorite,
    favoriteId,
    trackInfo,
    isLoading,
    isAccountCompliant
  ]);

  return {
    isFavorite,
    toggleFavoriteAction
  };
};

export default useFavorite;
