import styled from "styled-components";
import { buildColor } from "@tvg/design-system";
import { fontBold } from "@tvg/atomic-ui/_static/Typography";

export const Container = styled.section<{
  addMargin: boolean;
}>`
  background-color: ${buildColor("white", "900")};
  margin-bottom: ${({ addMargin }) => (addMargin ? "12px" : "0px")};
`;

export const Title = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  padding: 12px 14px;
`;
