import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import { getWagerProfile } from "@urp/store-selectors";

const useWagerProfile = () => {
  const userProfile: string = useSelector(getWagerProfile);
  const [wagerProfile, setWagerProfile] = useState("");
  useEffect(() => {
    if (userProfile) {
      setWagerProfile(userProfile);
    } else {
      // TODO IMPLEMENT BRAND BASE PORT
      setWagerProfile(getPortByBrand());
    }
  }, [userProfile]);
  return wagerProfile;
};

export default useWagerProfile;
