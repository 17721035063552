// @flow
import React from "react";
import RowLetter from "./styled-components";

type Props = {
  /**
   * letter of the separator
   */
  letter: string,
  /**
   * new styles for racetracks page
   */
  isSEORacetracks: boolean
};

const TrackSplitter = ({ letter, isSEORacetracks }: Props) => (
  <RowLetter isSEORacetracks={isSEORacetracks}>{letter}</RowLetter>
);

TrackSplitter.defaultProps = {
  letter: "",
  isSEORacetracks: false
};

// $FlowFixMe
export default React.memo(TrackSplitter);
