import { get, noop } from "lodash";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import { GraphProps } from "../types";

const handleResultsTL = (result: {}) => {
  const dispatch = get(result, "ownProps.dispatch", noop);
  const setTracksAction = get(result, "ownProps.setTracksData", noop);
  const setShouldUpdate = get(result, "ownProps.setShouldUpdate", noop);
  const tracks = get(result, "data.pastTracks", []);

  if (
    get(tracks, "length") &&
    get(tracks, "length") !== get(result, "ownProps.tracksData.length")
  ) {
    dispatch(setTracksAction(tracks));
    setShouldUpdate(false);
  }

  return {
    isLoading: get(result, "data.loading", false)
  };
};

const ApolloOptionsTL = {
  skip: (props: GraphProps) => !get(props, "shouldUpdate", false),
  options: (props: GraphProps) => {
    const variables = {
      wagerProfile: props.wagerProfile || getPortByBrand()
    };

    return {
      client: props.fcpClient,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: handleResultsTL
};

export default ApolloOptionsTL;
